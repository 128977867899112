import React, { Component } from 'react';
import axios from 'axios';
import { withRouter, Link } from 'react-router-dom';
import constClass from '../../Constants/Constants';
import Loading from '../Loading/Loading';
import Thanks from '../Images/thanks.png'
import Modal from 'react-modal';
import util from 'util';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel, AccordionItemState } from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import Select from 'react-select';
import ScrollToTop from '../Scroll/ScrollToTop';
import ProductList from './ProductList';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment';
import Payment from '../Payment/Payment';

Modal.setAppElement("#root");

class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      my_order: null,
      message: {},
      message_error: {},
      ready: false,
      product_category: [],
      receive_shop: null,
      planMaster: null,
      productMaster: null,
      productCategoryMaster: null,
      shopMaster: null,
      areaMaster: null,
      my_customer: null,
      area: undefined,
      name: null,
      kana: null,
      tel: null,
      postal_code: null,
      address: null,
      delivery_check: true,
      delivery_name: null,
      delivery_kana: null,
      delivery_tel: null,
      delivery_postal_code: null,
      delivery_address: null,
      privacypolicy: false,
      cart: [],
      current_order: null,
      selected_plan: [],
      selected_early_discount: null,
      changed_order: [],
      payment_data: {},
      not_liff_flag: false,
    };

    this.modalStyle = {
      overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: "rgba(0,0,0,0.5)"
      },
      content: {
        position: "absolute",
        left: '0.5rem',
        right: '0.5rem',
        top: '50%',
        bottom: 'auto',
        marginTop: '-25%',
        borderRadius: "0rem",
        padding: "0px",
        height: "auto"
      }
    };

    this.refreshOrder = this.refreshOrder.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.change = this.change.bind(this);
    this.handleRequestClick = this.handleRequestClick.bind(this);
    this.setReady = this.setReady.bind(this);
    this.addCart = this.addCart.bind(this);
    this.subCart = this.subCart.bind(this);
    this.displayCartCount = this.displayCartCount.bind(this);
    this.receiveShopRef = React.createRef();
    this.pageRef = React.createRef();
    this.returnPayment = this.returnPayment.bind(this);
  }

  async componentDidMount() {
    this.refreshOrder();
    this.refreshData();
    this.disableBounceScroll();
  }

  async componentWillUnmount() {
    // clearInterval(this.intervalCount);
  }

  disableBounceScroll() {
    let touchY = 0;

    document.body.addEventListener('touchstart', (e) => {
      touchY = e.touches[0].screenY;
    });

    document.body.addEventListener('touchmove', (e) => {
      let el = e.target;
      const moveY = e.touches[0].screenY;
      let noScroll = true;

      while (el !== null) {
        if (el.offsetHeight < el.scrollHeight) {
          if (touchY < moveY && el.scrollTop === 0) {
            break;
          }

          if (touchY > moveY && el.scrollTop === el.scrollHeight - el.offsetHeight) {
            break;
          }

          noScroll = false;
          break;
        }
        el = el.parentElement;
      }

      if (noScroll) {
        if (e.cancelable) {
          e.preventDefault();
        }
      }

      touchY = moveY;
    }, { passive: false });
  }

  change(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });

    var message = this.state.message;
    this.setState({
      message: { ...message, [name]: this.validator(name, value) }
    });

    // お客様情報の配送先連動処理
    if (this.state.delivery_check && ['name', 'kana', 'tel', 'postal_code', 'address'].includes(name)) {
      this.setState({
        ['delivery_' + name]: value,
      });
    }
    // お客様情報の連動チェック変更処理
    if (name === 'delivery_check' && value) {
      this.setState({
        delivery_name: this.state.name,
        delivery_kana: this.state.kana,
        delivery_tel: this.state.tel,
        delivery_postal_code: this.state.postal_code,
        delivery_address: this.state.address,
      })
    }
  }

  validator(name, value) {
    switch (name) {
      case 'tel':
        return this.phoneValidation(value);
      case 'delivery_tel':
        return this.phoneValidation(value);
      default:
        return '';
    }
  }

  phoneValidation(value) {
    const regex = /^[0-9\-()]+$/;
    if (!regex.test(value)) return '※正しい形式で電話番号を入力してください';
    return '';
  }

  scrollPageTop() {
    setTimeout(() => {
      this.pageRef.current.scrollTo(0, 0);
      window.scrollTo(0, 0);
    }, 200);
  }

  scrollWindowTop() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
  }

  async refreshOrder() {
    if (process.env.REACT_APP_ENV !== 'dev') {
      await window.liff.ready;
    }
    if (!this.props.liff_access_token) {
      if (!this.intervalOrder) {
        this.intervalOrder = setInterval(() => {
          this.refreshOrder();
        }, 500);
      }
    } else {
      clearInterval(this.intervalOrder);
      const reg_params = {
        "operator": "and",
        "where": [
          {
            "line_id": this.props.liff_access_token
          }
        ]
      }
      const my_order = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/order/search/`, reg_params)).data;
      this.setState({
        my_order: my_order,
        disabled: false,
        message_error: {}
      });
      if (this.props.page === constClass.CANCEL) {
        this.submitCancel('CCL');
      } else if (this.props.page === constClass.PAYMENT && this.props.current_order !== this.state.payment_data.id) {
        // 決済画面でcurrent_orderに値が設定されている（payment_dataと違う値）場合は決済データ取得
        this.submitPayment(this.props.current_order);
      } else if (this.props.page === constClass.ORDERFINISH && this.props.current_order &&
        my_order.find(o => o.order_id === this.props.current_order) && [constClass.STATUS.CCL, constClass.STATUS.CUPAY].includes(my_order.find(o => o.order_id === this.props.current_order).status)) {
        // 注文完了画面でcurrent_orderに値が設定されていて該当orderがキャンセル済みの場合は注文確認画面へ
        this.props.history.replace(`/?page=${constClass.CHANGE}&current_order=${this.props.current_order}&retmenu=1`);
      }
      this.setReady();
    }
  }

  async refreshData() {
    if (process.env.REACT_APP_ENV !== 'dev') {
      await window.liff.ready;
    }
    if (!this.props.liff_access_token) {
      if (!this.intervalMaster) {
        this.intervalMaster = setInterval(() => {
          this.refreshData();
        }, 500);
      }
    } else {
      if (process.env.REACT_APP_ENV !== 'dev' && this.props.liff_access_token === 'token_unusable') { // トークン取得不可→非LINEブラウザ
        this.setState({ not_liff_flag: true });
        return;
      }
      clearInterval(this.intervalMaster);
      // 各種APIコール
      const planPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/plan/list/`, { line_id: this.props.liff_access_token });
      const productPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/product/list/`, { line_id: this.props.liff_access_token });
      const productCategoryPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/productcategory/list/`, { line_id: this.props.liff_access_token });
      const shopPromise = axios.get(`${process.env.REACT_APP_BACKEND_URL}/shop/`);
      const areaPromise = axios.get(`${process.env.REACT_APP_BACKEND_URL}/area/`);
      const customerPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/search/`, { line_id: this.props.liff_access_token });
      const planMaster = (await planPromise).data;
      const productMaster = (await productPromise).data;
      const productCategoryMaster = (await productCategoryPromise).data;
      const shopMaster = (await shopPromise).data;
      const areaMaster = (await areaPromise).data;
      const customer = (await customerPromise).data;
      this.setState({
        planMaster,
        productMaster,
        productCategoryMaster,
        shopMaster: shopMaster.filter(s => s.view_flag),
        areaMaster,
        my_customer: customer.find(c => c.line_id === this.props.liff_user_id),
      });
      if (this.state.my_customer) {
        this.setState({
          receive_shop: this.state.my_customer.receive_shop,
          name: this.state.my_customer.customer_name,
          kana: this.state.my_customer.customer_kana,
          tel: this.state.my_customer.customer_tel,
          postal_code: this.state.my_customer.customer_postal_code,
          address: this.state.my_customer.customer_address,
          delivery_name: this.state.my_customer.customer_name,
          delivery_kana: this.state.my_customer.customer_kana,
          delivery_tel: this.state.my_customer.customer_tel,
          delivery_postal_code: this.state.my_customer.customer_postal_code,
          delivery_address: this.state.my_customer.customer_address,
        });
      }

      this.setReady();
    }
  }

  setReady() {
    if (this.state.my_order !== null &&
      this.state.my_customer !== null &&
      this.state.productMaster !== null &&
      this.state.planMaster !== null &&
      this.state.productCategoryMaster !== null &&
      this.state.shopMaster !== null) {
      this.setState({ ready: true });
    } else {
      this.setState({ ready: false });
    }
  }

  addCart(productId) {
    const product = this.state.productMaster.find(p => p.product_id === productId);
    const plan = this.state.planMaster.find(p => p.plan_id === product.plan_id);
    var cart = this.state.cart;
    var message = this.state.message;
    message = { ...message, [productId]: undefined };
    // カートの企画チェック
    var planIdx = cart.findIndex(c => c.planId === product.plan_id);
    // 同じ企画の商品が無い
    if (planIdx < 0) {
      cart.push({ planId: product.plan_id, detail: [], payment_flag: plan.payment_flag === constClass.FLAG.ON ? constClass.FLAG.ON : constClass.FLAG.OFF, delivery_flag: plan.delivery_flag === constClass.FLAG.ON ? constClass.FLAG.ON : constClass.FLAG.OFF });
      planIdx = cart.findIndex(c => c.planId === product.plan_id);
    }
    var idx = cart[planIdx].detail.findIndex(c => c.productId === productId);
    // カートに該当商品がある
    if (idx >= 0) {
      if ((product.year_round_type !== "1" || product.stock > 0) && cart[planIdx].detail[idx].count + 1 + product.order_count > product.stock) { //在庫を超える
        message = { ...message, [productId]: 'これ以上予約できません' };
      } else { //在庫を超えない
        cart[planIdx].detail[idx].count = cart[planIdx].detail[idx].count + 1;
      }
    }
    // カートに該当商品がない
    else {
      cart[planIdx].detail.push({
        productId,
        price_before_tax: product.price_before_tax,
        price: product.price,
        early_discount: this.checkEarlyDiscount(product) ? constClass.EARLY_DISCOUNT.ON : constClass.EARLY_DISCOUNT.OFF,
        early_discount_rate: product.early_discount_rate,
        count: 1
      })
    }
    this.setState({ cart, message });
  }

  subCart(productId) {
    const product = this.state.productMaster.find(p => p.product_id === productId);
    var cart = this.state.cart;
    var message = this.state.message;
    message = { ...message, [productId]: undefined };
    // カートの企画チェック
    var planIdx = cart.findIndex(c => c.planId === product.plan_id);
    // 同じ企画の商品がある
    if (planIdx >= 0) {
      var idx = cart[planIdx].detail.findIndex(c => c.productId === productId);
      // カートに該当商品がある
      if (idx >= 0) {
        // 1個→0個の場合は削除
        if (cart[planIdx].detail[idx].count <= 1) {
          cart[planIdx].detail.splice(idx, 1);
          // 企画内が0件になれば企画を削除
          if (cart[planIdx].detail.length <= 0) {
            cart.splice(planIdx, 1);
          }
        }
        // 他は1個マイナス
        else {
          cart[planIdx].detail[idx].count = cart[planIdx].detail[idx].count - 1;
        }
      }
      // カートに該当商品がない→何もしない
    }
    // 同じ企画の商品がない→何もしない
    this.setState({ cart, message });
  }

  deleteCart(productId) {
    const product = this.state.productMaster.find(p => p.product_id === productId);
    var cart = this.state.cart;
    var message = this.state.message;
    message = { ...message, [productId]: undefined };
    // カートの企画チェック
    var planIdx = cart.findIndex(c => c.planId === product.plan_id);
    // 同じ企画の商品がある
    if (planIdx >= 0) {
      var idx = cart[planIdx].detail.findIndex(c => c.productId === productId);
      // カートに該当商品がある
      if (idx >= 0) {
        cart[planIdx].detail.splice(idx, 1);
        // 企画内が0件になれば企画を削除
        if (cart[planIdx].detail.length <= 0) {
          cart.splice(planIdx, 1);
        }
      }
      // カートに該当商品がない→何もしない

    }
    // 同じ企画の商品がない→何もしない
    this.setState({ cart, message });
  }

  setCartFlag(planId, flag_name, value) {
    var cart = this.state.cart;
    // カートの企画チェック
    var planIdx = cart.findIndex(c => c.planId === planId);
    // 同じ企画の商品がある
    if (planIdx >= 0) {
      // フラグをセット
      cart[planIdx][flag_name] = value;
    }
    // 同じ企画の商品がない→何もしない
    this.setState({ cart });
  }

  addOrder(orderId, productId) {
    const product = this.state.productMaster.find(p => p.product_id === productId);
    var order = this.state.my_order;
    var message = this.state.message;
    message = { ...message, [productId]: undefined };
    var orderIdx = order.findIndex(o => o.order_id === orderId);
    // 変更量確認
    var changed_order = this.state.changed_order;
    var corderIdx = changed_order.findIndex(o => o.order_id === orderId);
    if (corderIdx < 0) {
      changed_order.push({ order_id: orderId, [productId]: 0 });
      corderIdx = changed_order.findIndex(o => o.order_id === orderId);
    }
    changed_order[corderIdx] = { [productId]: 0, ...changed_order[corderIdx] };
    if (changed_order[corderIdx][productId] + 1 > 0  // 変更量が1以上→在庫チェックが必要
      && (product.year_round_type !== "1" || product.stock > 0) //通年設定なしまたは在庫数が0より大きい→在庫チェックが必要
      && (product.order_count + changed_order[corderIdx][productId] + 1) > product.stock) { //在庫を超える
      message = { ...message, [productId]: 'これ以上予約できません' };
    } else {
      changed_order[corderIdx][productId] += 1;
      // 変更済み
      order[orderIdx].changed = true;
      var idx = order[orderIdx].t_order_detail.findIndex(c => c.product_id === productId);
      // カートに該当商品がある
      if (idx >= 0) {
        order[orderIdx].t_order_detail[idx].count += 1;
      }
      // カートに該当商品がない
      else {
        order[orderIdx].t_order_detail.push({
          product_id: productId,
          price_before_tax: product.price_before_tax,
          price: product.price,
          early_discount: this.checkEarlyDiscount(product) ? constClass.EARLY_DISCOUNT.ON : constClass.EARLY_DISCOUNT.OFF,
          early_discount_rate: product.early_discount_rate,
          count: 1
        })
      }
    }
    this.setState({ my_order: order, changed_order, message });
  }

  subOrder(orderId, productId) {
    var order = this.state.my_order;
    var orderIdx = order.findIndex(o => o.order_id === orderId);
    var message = this.state.message;
    message = { ...message, [productId]: undefined };
    // 変更量確認
    var changed_order = this.state.changed_order;
    var corderIdx = changed_order.findIndex(o => o.order_id === orderId);
    if (corderIdx < 0) {
      changed_order.push({ order_id: orderId, [productId]: 0 });
      corderIdx = changed_order.findIndex(o => o.order_id === orderId);
    }
    changed_order[corderIdx] = { [productId]: 0, ...changed_order[corderIdx] };
    // 変更済み
    order[orderIdx].changed = true;
    var idx = order[orderIdx].t_order_detail.findIndex(c => c.product_id === productId);
    // カートに該当商品がある
    if (idx >= 0) {
      // 1個→0個の場合は0固定
      if (order[orderIdx].t_order_detail[idx].count < 1) {
        order[orderIdx].t_order_detail[idx].count = 0;
      }
      // 他は1個マイナス
      else {
        order[orderIdx].t_order_detail[idx].count -= 1;
        changed_order[corderIdx][productId] -= 1;
      }
    }
    // カートに該当商品がない→何もしない
    this.setState({ my_order: order, changed_order, message });
  }

  deleteOrder(orderId, productId) {
    var order = this.state.my_order;
    var orderIdx = order.findIndex(o => o.order_id === orderId);
    var message = this.state.message;
    message = { ...message, [productId]: undefined };
    var message_error = this.state.message_error;
    message_error = { ...message, [productId]: undefined };
    // 変更済み
    order[orderIdx].changed = true;
    var idx = order[orderIdx].t_order_detail.findIndex(c => c.product_id === productId);
    // カートに該当商品がある
    if (idx >= 0) {
      order[orderIdx].t_order_detail.splice(idx, 1);
    }
    // カートに該当商品がない→何もしない
    this.setState({ my_order: order, message, message_error });
  }

  setProductCategory(product_category) {
    this.setState({ product_category });
  }

  setArea(area) {
    this.setState({ area: Number(area) });
    // 選択中の受取店舗が選択後の地区に含まれない場合
    if (!(this.state.shopMaster.filter(s => !area || s.area_id === Number(area)).map(s => s.shop_id).includes(this.state.receive_shop))) {
      this.setState({ receive_shop: undefined }); //受取店舗クリア
      this.receiveShopRef.current.selectedIndex = 0;
    }
  }

  setReceiveShop(receive_shop) {
    this.setState({ receive_shop: Number(receive_shop) });
  }

  setReceiveDate(plan_id, date) {
    var cart = this.state.cart;
    // カートの企画チェック
    var planIdx = cart.findIndex(c => c.planId === plan_id);
    date = date ? date.substr(0, 4) + '-' + date.substr(4, 2) + '-' + date.substr(6, 2) : '';
    cart[planIdx].receiveDate = date;
    this.setState({ cart });
  }

  setReceiveTime(plan_id, time) {
    var cart = this.state.cart;
    // カートの企画チェック
    var planIdx = cart.findIndex(c => c.planId === plan_id);
    cart[planIdx].receiveTime = time;
    this.setState({ cart });
  }

  setOrderReceiveDate(order_id, date) {
    var order = this.state.my_order;
    // カートの企画チェック
    var orderIdx = order.findIndex(c => c.order_id === order_id);
    date = date ? date.substr(0, 4) + '-' + date.substr(4, 2) + '-' + date.substr(6, 2) : '';
    order[orderIdx].receive_date = date;
    order[orderIdx].changed = true;
    this.setState({ my_order: order });
  }

  setOrderReceiveTime(order_id, time) {
    var order = this.state.my_order;
    // カートの企画チェック
    var orderIdx = order.findIndex(c => c.order_id === order_id);
    order[orderIdx].receive_time = time;
    order[orderIdx].changed = true;
    this.setState({ my_order: order });
  }

  checkDataInvalid() {
    var cart = this.state.cart;
    var result = false;
    cart.forEach(c => {
      if (c.receiveDate === null || c.receiveTime === null) {
        result = true;
      }
    })
    return result;
  }

  getDateString(date = null) {
    const sysdate = new Date();
    if (date === null) {
      date = sysdate;
    }
    return String(date.getFullYear()) + (('00' + String(date.getMonth() + 1)).slice(-2)) + (('00' + String(date.getDate())).slice(-2));
  }

  getShortDateString(date) {
    date = date.replace(/-/g, '');
    return Number(date.substr(4, 2)).toString() + "/" + Number(date.substr(6, 2)).toString();
  }

  checkEarlyDiscount(product) {
    return product.early_discount_start <= this.getDateString() && product.early_discount_end >= this.getDateString();
  }

  getEarlyDiscountPrice(item) {
    if (item.early_discount === constClass.EARLY_DISCOUNT.ON || (item.early_discount === undefined && this.checkEarlyDiscount(item))) {
      return Math.floor(item.price_before_tax * (item.early_discount_rate));
    }
    else {
      return 0;
    }
  }

  getChangeLimitFromOrder(order) {
    if (order.year_round_type === "1") {
      //受取日から変更可能日数最大を引いた日付を返す
      return moment(new Date(order.receive_date.replace(/-/g, '/'))).subtract(
        order.t_order_detail.reduce((max, d) => max < d.m_product.change_limit_days ? d.m_product.change_limit_days : max, '0'), 'd'
      ).format('YYYYMMDD');
    } else {
      return order.t_order_detail.reduce((min, d) => min > d.m_product.change_limit ? d.m_product.change_limit : min, '99999999');
    }
  }

  getMinDateReceiveDate(receive_date, productMaster) {
    //設定済の受取日と受取可能日の内小さい日付の方を返す
    const date1 = moment(new Date(receive_date.replace(/-/g, '/')));
    const date2 = moment().add(parseInt(productMaster.sort((a, b) => parseInt(b.receipt_limit_days) - parseInt(a.receipt_limit_days))[0].receipt_limit_days), 'days');
    if (date2.isAfter(date1)) return new Date(date1.format('YYYY/MM/DD'));
    return new Date(date2.format('YYYY/MM/DD'));
  }
  getDuplicateReceiveDate(productMaster) {
    if (productMaster.length <= 0) { // 0件は終了
      return [];
    }
    var res = productMaster[0].m_product_receive.map(r => r.receive_date); // 最初の1件目
    productMaster.forEach(product => {
      var arr = product.m_product_receive.map(r => r.receive_date); // receive_dateの配列作成
      res = [...res, ...arr].filter(item => res.includes(item) && arr.includes(item)); // resとarrのどちらにも含まれるものを抽出
      res = Array.from(new Set(res).values()); // Setで重複除外して配列に変換
    });
    return res.sort((a, b) => Number(a) - Number(b));
  }

  getDuplicateNgShop(productMaster) {
    if (productMaster.length <= 0) { // 0件は終了
      return [];
    }
    var res = productMaster[0].m_ng_shop.map(r => r.shop_id); // 最初の1件目
    productMaster.forEach(product => {
      var arr = product.m_ng_shop.map(r => r.shop_id); // receive_dateの配列作成
      res = [...res, ...arr].filter(item => res.includes(item) && arr.includes(item)); // resとarrのどちらにも含まれるものを抽出
      res = Array.from(new Set(res).values()); // Setで重複除外して配列に変換
    });
    return res.sort((a, b) => Number(a) - Number(b));
  }

  getOrderTotal(order) {
    // 金額計算
    var subtotal = 0; //合計金額
    var discount = 0; //割引金額
    var tax_rates = []; // 税率別合計金額
    var tax = 0; //税額
    var total = 0; //税込金額
    var detail; //明細
    var payment = 0; //支払金額
    if (order.detail) { //detailがある＝カートの場合
      detail = order.detail;
    }
    if (order.t_order_detail) { //t_order_detailがある＝Orderデータの場合
      detail = order.t_order_detail;
    }
    // 金額計算を待ち合わせる
    (detail.forEach((item) => {
      const product = this.state.productMaster.find(p => p.product_id === item.product_id || p.product_id === item.productId);
      // 合計金額: 税抜単価 * 数量
      var st = product.price_before_tax * item.count;
      subtotal += st;

      // 割引金額: 税抜金額 * 割引率 の切り上げ
      var dc = 0;
      if (item.early_discount === constClass.EARLY_DISCOUNT.ON) {
        dc = Math.ceil(st * product.early_discount_rate);
      }
      discount += dc;

      //税率別合計金額
      const tax_idx = tax_rates.findIndex(t => t.tax_rate === Number(product.tax_rate));
      // 既に税率がある
      if (tax_idx >= 0) {
        tax_rates[tax_idx].total += (st - dc); // 合計金額 - 割引金額
      }
      // 新しい税率
      else {
        tax_rates.push({ tax_rate: Number(product.tax_rate), total: (st - dc) }); // 合計金額 - 割引金額
      }
    }));
    tax = tax_rates.reduce((sum, item) => sum += Math.floor(item.total * item.tax_rate), 0);// 税額計算 sum(合計金額 - 割引金額) * 税率
    total = tax_rates.reduce((sum, item) => sum += Math.floor(item.total * (1 + item.tax_rate)), 0); // 総合計 sum(合計金額 - 割引金額) * (1 + 税率)
    return { subtotal, discount, tax, total, payment, tax_rates };
  }

  displayCartCount(plan_id, product_id) {
    var ret = 0;
    if (this.state.cart.find(c => c.planId === plan_id)) { //企画有無チェック
      if (this.state.cart.find(c => c.planId === plan_id).detail.find(c => c.productId === product_id)) { //商品有無チェック
        ret = this.state.cart.find(c => c.planId === plan_id).detail.find(c => c.productId === product_id).count;
      } else {
        ret = 0;
      }
    } else {
      ret = 0;
    }
    const product = this.state.productMaster.find(p => p.product_id === product_id);
    if ((product.year_round_type !== "1" || product.stock > 0) && (product.stock - product.order_count) <= 0) { //在庫切れ
      ret = '売り切れ';
    }
    if (product.m_ng_shop.map(s => s.shop_id).includes(this.state.my_customer.receive_shop)) { //受取不可
      ret = '受取対象外';
    }
    return ret;
  }

  checkOrderChangeLimit(checkorderid = null) {
    if (checkorderid === null && this.props.current_order) checkorderid = this.props.current_order;
    if (checkorderid) {
      const order = this.state.my_order.find(o => o.order_id === checkorderid);
      if (order.status !== constClass.STATUS.REG && order.status !== constClass.STATUS.PRE && order.status !== constClass.STATUS.UPAY) { // ステータスが確定（注文済み）、事前通知済み、未決済以外は変更不可
        return false
      }
      const change_limit = this.getChangeLimitFromOrder(order);
      if (this.getDateString() <= change_limit) { // 今日が変更可能日より前なら変更可
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  setSelectedPlan(selected_plan) {
    this.setState({ selected_plan });
  }

  toggleEarlyDiscount() {
    var selected_early_discount = this.state.selected_early_discount;
    if (selected_early_discount) { //既に選択済み
      selected_early_discount = false;
    } else { //未選択
      selected_early_discount = true;
    }
    this.setState({ selected_early_discount });
  }

  async submit() {
    if (this.checkDataInvalid() || this.state.disabled) {
      return;
    }
    if (this.state.cart.filter(c => c.payment_flag === constClass.FLAG.ON).length <= 0 && !window.confirm('注文を確定しますがよろしいですか？')) {
      return;
    }
    this.setState({ disabled: true });
    var message = this.state.message;
    // 結果保持用変数群
    var my_order = []; // orderデータ
    var errors = []; // エラー
    // 結果待ち合わせ
    await Promise.all(this.state.cart.map(async (plan) => {
      // const p = this.state.planMaster.find(p => p.plan_id === plan.planId);
      // orderデータ準備
      var data = {
        'line_id': this.props.liff_access_token,
        'customer_name': this.state.my_customer.customer_name,
        'customer_kana': this.state.my_customer.customer_kana,
        'receive_shop': this.state.my_customer.receive_shop,
        'customer_tel': this.state.my_customer.customer_tel,
        // 'payment_flag': [constClass.FLAG.ON, constClass.FLAG.BOTH].includes(p.payment_flag) ? constClass.FLAG.ON : constClass.FLAG.OFF,
        // 'delivery_flag': [constClass.FLAG.ON, constClass.FLAG.BOTH].includes(p.delivery_flag) ? constClass.FLAG.ON : constClass.FLAG.OFF,
        // 'customer_postal_code': [constClass.FLAG.ON, constClass.FLAG.BOTH].includes(p.delivery_flag) ? this.state.my_customer.customer_postal_code : null,
        // 'customer_address': [constClass.FLAG.ON, constClass.FLAG.BOTH].includes(p.delivery_flag) ? this.state.my_customer.customer_address : null,
        'payment_flag': plan.payment_flag,
        'delivery_flag': plan.delivery_flag,
        'customer_postal_code': plan.delivery_flag === constClass.FLAG.ON ? this.state.my_customer.customer_postal_code : null,
        'customer_address': plan.delivery_flag === constClass.FLAG.ON ? this.state.my_customer.customer_address : null,
        'delivery_name': plan.delivery_flag === constClass.FLAG.ON ? plan.delivery_name : null,
        'delivery_kana': plan.delivery_flag === constClass.FLAG.ON ? plan.delivery_kana : null,
        'delivery_tel': plan.delivery_flag === constClass.FLAG.ON ? plan.delivery_tel : null,
        'delivery_postal_code': plan.delivery_flag === constClass.FLAG.ON ? plan.delivery_postal_code : null,
        'delivery_address': plan.delivery_flag === constClass.FLAG.ON ? plan.delivery_address : null,
        'plan_id': plan.planId,
        'receive_date': plan.receiveDate,
        'receive_time': plan.receiveTime,
        'employee_flag': this.state.my_customer.employee_flag,
        't_order_detail': [],
      };
      // order_detailデータ準備
      plan.detail.forEach((detail) => {
        data.t_order_detail.push({
          'product_id': detail.productId,
          'early_discount': detail.early_discount,
          'count': detail.count,
        });
      });
      // API実行
      try {
        const result = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/order/line/`, data)).data;
        if (result.result) {
          my_order.push(result.order);
        } else {
          errors.push('エラーが発生しました。もう一度お試しください。');
        }
      } catch (err) {
        errors.push(err);
      }
    }));

    // エラー件数0
    if (errors.length === 0) {
      // 事前決済判定
      // const plan = this.state.planMaster.filter(p => my_order.map(o => o.plan_id).includes(p.plan_id) && p.payment_flag === constClass.FLAG.ON).map(p => p.plan_id);
      // console.log(plan);
      if (my_order.filter(o => o.payment_flag === constClass.FLAG.ON).length > 0) {
        await this.refreshOrder();
        this.setState({ cart: [] });
        const p = this.state.planMaster.find(p => p.plan_id === my_order.find(o => o.payment_flag === constClass.FLAG.ON).plan_id);
        await this.submitPayment(my_order.find(o => o.payment_flag === constClass.FLAG.ON).order_id, p.plan_name);

      } else {
        await this.refreshOrder();
        this.refreshData();
        this.setState({ order_finish: my_order, cart: [], disabled: false, message: {} });
        this.props.history.push(`/?page=${constClass.ORDERFINISH}`);
      }
    }
    // エラーあり
    else {
      errors.forEach((e) => {
        if (e.response.data && e.response.data.message) {
          try {
            const error_list = JSON.parse(e.response.data.message);
            error_list.forEach(item => {
              message = { ...message, [item]: '在庫上限に達したため予約できません' };
            });
          } catch (err) {
            console.log(util.inspect(err));
          }
        }
      })
      await this.refreshData();
      this.setState({ disabled: false, message });
    }
  }

  async submitChange() {
    if (!this.state.my_order.reduce((flag, order) => (flag || order.changed), false) || this.state.disabled) {
      return;
    }
    this.setState({ disabled: true });
    var message = this.state.message;
    // 結果保持用変数群
    var my_order = []; // orderデータ
    var errors = []; // エラー
    // 結果待ち合わせ
    await Promise.all(this.state.my_order.filter(o => o.changed).map(async (order) => {
      // orderデータ準備
      order.line_id = this.props.liff_access_token;
      // API実行
      try {
        const result = (await axios.put(`${process.env.REACT_APP_BACKEND_URL}/order/line/${order.order_id}`, order)).data;
        if (result.result) {
          my_order.push(result.order);
        } else {
          errors.push('エラーが発生しました。もう一度お試しください。');
        }
      } catch (err) {
        errors.push(err);
      }
    }));

    // エラー件数0
    if (errors.length === 0) {
      // 事前決済判定
      if (my_order.filter(o => o.status === constClass.STATUS.UPAY).length > 0) {
        await this.refreshOrder();
        await this.submitPayment(my_order.find(o => o.status === constClass.STATUS.UPAY).order_id);

      } else {
        await this.refreshOrder();
        this.refreshData();
        this.setState({ order_finish: my_order, cart: [], disabled: false, message: {}, changed_order: [] });
        this.props.history.push(`/?page=${constClass.CHANGEFINISH}`);
      }
    }
    // エラーあり
    else {
      errors.forEach((e) => {
        if (e.response.data && e.response.data.message) {
          try {
            const error_list = JSON.parse(e.response.data.message);
            error_list.forEach(item => {
              message = { ...message, [item]: '在庫上限に達したため予約できません' };
            });
          } catch (err) {
            console.log(util.inspect(err));
            window.alert('エラーが発生しました。\n' + e.response.data.message);
          }
        }
      })
      await this.refreshData();
      this.setState({ disabled: false, message });
    }
  }

  async submitReceiveShop() {
    if (this.state.disabled) {
      return;
    }
    this.setState({ disabled: true });
    var data = {
      'line_id': this.props.liff_access_token,
      'customer_name': this.state.my_customer ? this.state.my_customer.customer_name : null,
      'customer_kana': this.state.my_customer ? this.state.my_customer.customer_kana : null,
      'receive_shop': this.state.receive_shop,
      'customer_tel': this.state.my_customer ? this.state.my_customer.customer_tel : null,
      'employee_flag': this.state.my_customer ? this.state.my_customer.employee_flag : null,
      'customer_postal_code': this.state.my_customer ? this.state.my_customer.customer_postal_code : null,
      'customer_address': this.state.my_customer ? this.state.my_customer.customer_address : null,
    };
    try {
      const my_customer = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/`, data)).data;
      var message = {};
      var cart = this.state.cart;
      this.state.productMaster.forEach(item => {
        if (item.m_ng_shop.map(s => s.shop_id).includes(my_customer.receive_shop)) {
          message = { ...message, [item.product_id]: `${this.state.shopMaster.find(s => s.shop_id === my_customer.receive_shop).shop_name}では受け取りできません` };
          var cartIdx = cart.findIndex(p => p.planId === item.plan_id);
          if (cartIdx >= 0) { // 企画商品を選択済み
            var idx = cart[cartIdx].detail.findIndex(p => p.productId === item.product_id);
            if (idx >= 0) { // 商品をカートに追加済み
              cart[cartIdx].detail.splice(idx, 1); // 受取不可の商品はカートから削除する
            }
          }
        } else {
          message = { ...message, [item.product_id]: undefined };
        }
      });
      this.setState({ my_customer, disabled: false, message, cart });
      this.props.history.push(`/?page=${constClass.REQUEST}`);
    } catch (err) {
      // alert(err.toString());
      alert('システムエラーが発生しました。');
      this.setState({ message: err, disabled: false });
    }
  }

  async submitCustomer(page) {
    if (this.state.disabled) {
      return;
    }
    this.setState({ disabled: true });
    if (this.props.plan_id) {
      // plan_idが選択されている（カート内の配送先変更）場合は該当のカートの配送先のみ変更
      this.state.cart.filter(c => c.planId === this.props.plan_id).forEach(c => {
        c.delivery_name = this.state.delivery_name;
        c.delivery_kana = this.state.delivery_kana;
        c.delivery_tel = this.state.delivery_tel;
        c.delivery_postal_code = this.state.delivery_postal_code;
        c.delivery_address = this.state.delivery_address;
      });
    } else {
      // カートがあればカート内に値を追加
      this.state.cart.forEach(c => {
        c.delivery_name = this.state.delivery_name;
        c.delivery_kana = this.state.delivery_kana;
        c.delivery_tel = this.state.delivery_tel;
        c.delivery_postal_code = this.state.delivery_postal_code;
        c.delivery_address = this.state.delivery_address;
      });
    }
    var data = {
      'line_id': this.props.liff_access_token,
      'customer_name': this.state.name,
      'customer_kana': this.state.kana,
      'receive_shop': this.state.my_customer ? this.state.my_customer.receive_shop : this.state.receive_shop,
      'customer_tel': this.state.tel,
      'employee_flag': this.state.my_customer ? this.state.my_customer.employee_flag : null,
      'customer_postal_code': this.state.postal_code,
      'customer_address': this.state.address,
    };
    try {
      const my_customer = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/`, data)).data;
      this.setState({ my_customer, disabled: false, message: {}, delivery_check: false });
      this.props.history.push(`/?page=${page}`);
    } catch (err) {
      // alert(err.toString());
      alert('システムエラーが発生しました。');
      this.setState({ message: err, disabled: false });
    }
  }

  async submitCardEdit() {
    const payment_data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/order/member/${this.props.current_order}`)).data;

    const form = document.createElement('form');
    form.action = payment_data.url;
    form.method = payment_data.method;

    // body に追加
    document.body.append(form);
    for (const i in payment_data.data) {
      // obj.hasOwnProperty() はオブジェクトのプロトタイプチェーンからプロパティを絞り込むために使用しています
      if (payment_data.data.hasOwnProperty(i)) {
        const input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', i);
        input.setAttribute('value', payment_data.data[i]);
        form.appendChild(input);
      }
    }

    // submit
    form.submit();
  }

  async submitPayment(order_id, plan_name = null) {
    this.setState({ disabled: true });
    const params = {
      Overview: plan_name,
      line_id: this.props.liff_access_token
    };
    const payment_data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/order/payment/${order_id}`, params)).data;

    // urlがある⇒画面遷移
    if (payment_data.url) {
      const form = document.createElement('form');
      form.action = payment_data.url;
      form.method = payment_data.method;

      // body に追加
      document.body.append(form);
      for (const i in payment_data.data) {
        // obj.hasOwnProperty() はオブジェクトのプロトタイプチェーンからプロパティを絞り込むために使用しています
        if (payment_data.data.hasOwnProperty(i)) {
          const input = document.createElement('input');
          input.setAttribute('type', 'hidden');
          input.setAttribute('name', i);
          input.setAttribute('value', payment_data.data[i]);
          form.appendChild(input);
        }
      }

      // submit
      form.submit();
    } else {
      // urlが無い⇒paymentモジュール画面へ遷移
      this.setState({ payment_data: payment_data.data, disabled: false });
      this.props.history.push(`/?page=${constClass.PAYMENT}&current_order=${order_id}`);
    }
  }

  async returnPayment() {
    await this.refreshOrder();
  }

  moveOrderCustomer() {
    if (this.state.disabled) {
      return;
    }
    var order = this.state.my_order;
    // カートの企画チェック
    var orderIdx = order.findIndex(c => c.order_id === this.props.current_order);
    var name = order[orderIdx].customer_name;
    var kana = order[orderIdx].customer_kana;
    var tel = order[orderIdx].customer_tel;
    var postal_code = order[orderIdx].customer_postal_code;
    var address = order[orderIdx].customer_address;
    var delivery_name = order[orderIdx].delivery_name;
    var delivery_kana = order[orderIdx].delivery_kana;
    var delivery_tel = order[orderIdx].delivery_tel;
    var delivery_postal_code = order[orderIdx].delivery_postal_code;
    var delivery_address = order[orderIdx].delivery_address;
    this.setState({ name, kana, tel, postal_code, address, delivery_name, delivery_kana, delivery_tel, delivery_postal_code, delivery_address });
    this.props.history.push(`/?page=${constClass.ORDERUSERCHANGE}&current_order=${this.props.current_order}`);
  }

  cancelOrderCustomer() {
    if (this.state.disabled) {
      return;
    }
    var name = this.state.my_customer.customer_name;
    var kana = this.state.my_customer.customer_kana;
    var tel = this.state.my_customer.customer_tel;
    var postal_code = this.state.my_customer.customer_postal_code;
    var address = this.state.my_customer.customer_address;
    this.setState({ name, kana, tel, postal_code, address, delivery_name: name, delivery_kana: kana, delivery_tel: tel, delivery_postal_code: postal_code, delivery_address: address });
    if (this.props.history.length <= 1) {
      this.props.history.push('/');
    } else {
      this.props.history.goBack();
    }
  }

  submitOrderCustomer() {
    if (this.state.disabled) {
      return;
    }
    var order = this.state.my_order;
    // カートの企画チェック
    var orderIdx = order.findIndex(c => c.order_id === this.props.current_order);
    order[orderIdx].customer_name = this.state.name;
    order[orderIdx].customer_kana = this.state.kana;
    order[orderIdx].customer_tel = this.state.tel;
    order[orderIdx].customer_postal_code = this.state.postal_code;
    order[orderIdx].customer_address = this.state.address;
    order[orderIdx].delivery_name = this.state.delivery_name;
    order[orderIdx].delivery_kana = this.state.delivery_kana;
    order[orderIdx].delivery_tel = this.state.delivery_tel;
    order[orderIdx].delivery_postal_code = this.state.delivery_postal_code;
    order[orderIdx].delivery_address = this.state.delivery_address;
    order[orderIdx].changed = true;
    var name = this.state.my_customer.customer_name;
    var kana = this.state.my_customer.customer_kana;
    var tel = this.state.my_customer.customer_tel;
    var postal_code = this.state.my_customer.customer_postal_code;
    var address = this.state.my_customer.customer_address;
    this.setState({ my_order: order, name, kana, tel, postal_code, address, delivery_name: name, delivery_kana: kana, delivery_tel: tel, delivery_postal_code: postal_code, delivery_address: address });
    this.props.history.goBack();
  }

  moveOrderShop() {
    if (this.state.disabled) {
      return;
    }
    var order = this.state.my_order;
    // カートの企画チェック
    var orderIdx = order.findIndex(c => c.order_id === this.props.current_order);
    var receive_shop = order[orderIdx].receive_shop;
    var before_area = this.state.area;
    this.setState({ receive_shop, before_area, area: null });
    this.props.history.push(`/?page=${constClass.ORDERSHOPCHANGE}&current_order=${this.props.current_order}`);
  }

  cancelOrderShop() {
    if (this.state.disabled) {
      return;
    }
    var receive_shop = this.state.my_customer.receive_shop;
    var before_area = this.state.before_area;
    this.setState({ receive_shop, area: before_area, before_area: null });
    if (this.props.history.length <= 1) {
      this.props.history.push('/');
    } else {
      this.props.history.goBack();
    }
  }

  submitOrderShop() {
    if (this.state.disabled) {
      return;
    }
    var order = this.state.my_order;
    var message_error = this.state.message_error;
    // カートの企画チェック
    var orderIdx = order.findIndex(c => c.order_id === this.props.current_order);
    order[orderIdx].receive_shop = this.state.receive_shop;
    order[orderIdx].changed = true;
    var receive_shop = this.state.my_customer.receive_shop;
    var before_area = this.state.before_area;
    // 受取不可店舗チェック
    order[orderIdx].t_order_detail.forEach(item => {
      if (item.m_product.m_ng_shops.map(s => s.shop_id).includes(this.state.receive_shop)) { //受取不可店舗に含む
        message_error = { ...message_error, [item.product_id]: 'この店舗では受け取りできません' };
      } else {
        message_error = { ...message_error, [item.product_id]: undefined };
      }
    })
    this.setState({ my_order: order, receive_shop, area: before_area, before_area: null, message_error });
    this.props.history.goBack();
  }

  async cancelChange() {
    if (this.state.disabled) {
      return;
    }
    this.setState({ disabled: true });
    try {
      await this.refreshOrder();
      if (this.props.history.length <= 1) {
        this.props.history.push('/');
      } else {
        if (this.props.history.location.search.match(/retmenu=1/)) {
          this.props.history.push('/');
        } else {
          this.props.history.goBack();
        }
      }
    } catch (err) {
      alert(err.toString());
      this.setState({ message: err, disabled: false });
    }
  }

  async submitCurrentOrder(order_id, page) {
    this.setState({ message: {} });
    this.props.setCurrentOrder(order_id);
    this.props.history.push(`/?page=${page}&current_order=${order_id}`);
    this.scrollPageTop();
  }

  async submitReceive() {
    if (this.state.disabled) {
      return;
    }
    if (!window.confirm('本当に受け取りますか？受け取りはスタッフの確認が必要です')) {
      return;
    }
    this.setState({ disabled: true });
    // 送信データ
    const data = {
      status: constClass.STATUS.FIN,
      line_id: this.props.liff_access_token,
    }
    // API実行
    try {
      const result = (await axios.put(`${process.env.REACT_APP_BACKEND_URL}/order/status/line/${this.props.current_order}`, data)).data;
      if (result.result) {
        this.setState({ disabled: false, message: {} });
        this.refreshOrder();
        this.props.history.push(`/?page=${constClass.RECEIVEFINISH}`);
      } else {
        alert('エラーが発生しました。もう一度お試しください。');
        this.setState({ disabled: false, message: {} });
      }
    } catch (err) {
      // alert(util.inspect(err));
      if (err.response && err.response.data && err.response.data.msg) {
        alert(err.response.data.msg);
      } else {
        alert('システムエラーが発生しました。');
      }
      this.setState({ disabled: false, message: {} });
    }
  }

  submitRequest() {
    this.props.history.push(`/?page=${constClass.USER}`);
    this.scrollPageTop();
  }

  async submitCancel() {
    if (this.state.disabled) {
      return;
    }
    if (!window.confirm('本当にキャンセルしますか？この操作は取り消しできません')) {
      return;
    }
    this.setState({ disabled: true });
    // 送信データ
    const data = {
      status: constClass.STATUS.CCL,
      line_id: this.props.liff_access_token,
    }
    // API実行
    try {
      const result = (await axios.put(`${process.env.REACT_APP_BACKEND_URL}/order/status/line/${this.props.current_order}`, data)).data;
      if (result.result) {
        this.setState({ disabled: false, message: {} });
        this.refreshOrder();
        window.alert(`注文をキャンセルしました。`);
        this.props.history.push(`/?page=${constClass.HISTORY}`);
      } else {
        alert('エラーが発生しました。もう一度お試しください。');
        this.setState({ disabled: false, message: {} });
      }
    } catch (err) {
      // alert(util.inspect(err));
      if (err.response && err.response.data && err.response.data.msg) {
        alert(err.response.data.msg);
      } else {
        alert('システムエラーが発生しました。');
      }
      this.setState({ disabled: false, message: {} });
    }
  }

  async submitEmployee() {
    if (this.state.disabled) {
      return;
    }
    this.setState({ disabled: true });
    // 送信データ
    const data = {
      line_id: this.props.liff_access_token,
      employee_flag: constClass.EARLY_DISCOUNT.ON
    }
    // API実行
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/employee`, data);
      this.setState({ disabled: false, message: {} });
      this.refreshData();
      window.alert(`登録が完了しました。`);
      this.props.history.replace('/');
    } catch (err) {
      // alert(util.inspect(err));
      if (err.response && err.response.data && err.response.data.msg) {
        alert(err.response.data.msg);
      } else {
        alert('システムエラーが発生しました。');
      }
      this.setState({ disabled: false, message: {} });
    }
  }

  async submitEmployeeCancel() {
    if (this.state.disabled) {
      return;
    }
    if (!window.confirm('本当に従業員登録を解除しますか？')) {
      return;
    }
    this.setState({ disabled: true });
    // 送信データ
    const data = {
      line_id: this.props.liff_access_token,
      employee_flag: constClass.EARLY_DISCOUNT.OFF
    }
    // API実行
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/employee`, data);
      this.setState({ disabled: false, message: {} });
      this.refreshData();
      window.alert(`登録を解除しました。`);
      this.props.history.replace('/');
    } catch (err) {
      // alert(util.inspect(err));
      if (err.response && err.response.data && err.response.data.msg) {
        alert(err.response.data.msg);
      } else {
        alert('システムエラーが発生しました。');
      }
      this.setState({ disabled: false, message: {} });
    }
  }

  handleRequestClick() {
    this.props.setPage(constClass.REQUEST);
  }

  async searchAddress(postal_code, target) {
    this.setState({ disabled: true });
    const message = this.state.message;
    // API実行
    try {
      const data = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/address/${postal_code}`)).data;
      if (data) {
        var address = '';
        if (data.PrefecturesName) {
          address += data.PrefecturesName;
        }
        if (data.CityName) {
          address += data.CityName;
        }
        if (data.TownName) {
          address += data.TownName;
        }
        if (target === '' && this.state.delivery_check) {
          this.setState({ disabled: false, [target + 'address']: address, delivery_address: address, message: { ...message, [target + 'postal_code']: '', delivery_postal_code: '' } });
        } else {
          this.setState({ disabled: false, [target + 'address']: address, message: { ...message, [target + 'postal_code']: '' } });
        }
      } else {
        this.setState({ disabled: false, message: { ...message, [target + 'postal_code']: '郵便番号が見つかりません' } });
      }
    } catch (err) {
      // alert(util.inspect(err));
      if (err.response && err.response.data && err.response.data.msg) {
        alert(err.response.data.msg);
      } else {
        alert('システムエラーが発生しました。');
      }
      this.setState({ disabled: false });
    }
  }

  checkUserInvalid() {
    var result = false;
    // 基本情報の確認
    if (!this.state.name || !this.state.kana || !this.state.tel || this.state.disabled || !this.state.privacypolicy) {
      result = true;
    }
    // 注文入力時に配送専用がある or 注文変更時に配送のデータがある
    if ((this.props.page === constClass.USER && this.state.planMaster.filter(p => this.state.cart.map(c => c.planId).includes(p.plan_id)).reduce((prev, p) => [constClass.FLAG.ON].includes(p.delivery_flag) ? true : prev, false)) || //注文時ユーザ登録
      (this.props.page === constClass.ORDERUSERCHANGE && this.state.my_order.find(o => o.order_id === this.props.current_order).delivery_flag === constClass.FLAG.ON)) { // 注文情報変更
      if (!this.state.postal_code || !this.state.address ||
        !this.state.delivery_name || !this.state.delivery_kana || !this.state.delivery_tel || !this.state.delivery_postal_code || !this.state.delivery_address) {
        result = true;
      }
    }
    // エラーメッセージの確認
    if (this.state.message.tel || this.state.message.postal_code || this.state.message.delivery_postal_code) {
      result = true;
    }

    return result;
  }

  renderHeader() {
    return (
      <header className="header">
        <div className="row mx-0">
          <div className="col text-center">
            <img src="logo.png" alt="logo" />
          </div>
        </div>
      </header>
    );
  }
  renderPagetitle() {
    const titlename = () => {
      switch (this.props.page) {
        case constClass.PLAN: return `受取希望店舗選択`;
        case constClass.REQUEST: return `ご注文商品 選択`;
        case constClass.USER: return `お客様情報入力`;
        case constClass.CONFIRM: return `ご注文情報確認`;
        case constClass.ORDERFINISH: return `注文完了`;
        case constClass.HISTORY: return `ご注文履歴・変更`;
        case constClass.CHANGE: return `ご注文履歴・変更`;
        case constClass.CHANGEFINISH: return `注文完了`;
        case constClass.RECEIVESELECT: return `お受け取り`;
        case constClass.RECEIVE: return `お受け取り`;
        case constClass.RECEIVEFINISH: return `お受け取り完了`;
        case constClass.USERCHANGE: return `お客様情報`;
        case constClass.ORDERUSERCHANGE: return `ご注文履歴・変更`;
        case constClass.ORDERSHOPCHANGE: return `ご注文履歴・変更`;
        case constClass.PAYMENT: return `お支払い`;
        default: return `メニュー`;
      }
    }
    return (
      <div>
        <div className="row mx-0">
          <div className="col section py-0 line-height-2-2">
            <span className="d-inline-block align-middle">{titlename(this)}</span>
          </div>
        </div>
      </div>
    );
  }

  renderCondition() {
    return (
      <div className="row bg-ash mx-0 my-0 py-2 px-1-env">
        <div className="col">
          <div className="row">
            <div className="col">
              <Accordion allowZeroExpanded>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton className="px-0 py-2 m-0 border-none bg-white text-center">
                      絞り込み
                      <AccordionItemState>
                        {({ expanded }) => (expanded ? '▲' : '▼')}
                      </AccordionItemState>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className="p-1">
                    <div className="row">
                      <div className="col align-self-center text-center">
                        {this.state.planMaster.length > 1 &&
                          <div className="row mx--1">
                            <div className="col px-1 text-left">
                              予約パンフレットから絞り込む
                            </div>
                          </div>
                        }
                        {this.state.planMaster.length > 1 &&
                          <div className="row mx--1">
                            <div className="col px-1">
                              <Select
                                options={this.state.planMaster.map((item) => ({ label: item.plan_name, value: item.plan_id }))}
                                isMulti
                                onChange={(item) => this.setSelectedPlan(item.map(a => a.value))}
                                placeholder={`選択してください`} />
                            </div>
                          </div>
                        }
                        {this.state.productCategoryMaster.length > 1 &&
                          <div className="row mx--1">
                            <div className="col px-1 text-left">
                              カテゴリから絞り込む
                            </div>
                          </div>
                        }
                        {this.state.productCategoryMaster.length > 1 &&
                          <div className="row mx--1">
                            <div className="col px-1">
                              <Select
                                options={this.state.productCategoryMaster.map((item) => ({ label: item.product_category_name, value: item.product_category_id }))}
                                isMulti
                                onChange={(item) => this.setProductCategory(item.map(a => a.value))}
                                placeholder={`選択してください`} />
                            </div>
                          </div>
                        }
                        {this.state.productMaster.reduce((flg, item) => flg || this.checkEarlyDiscount(item), false) &&
                          <div className="row mx--1">
                            <div className="col px-1 text-left">
                              割引対象から絞り込む
                            </div>
                          </div>
                        }
                        {this.state.productMaster.reduce((flg, item) => flg || this.checkEarlyDiscount(item), false) &&
                          <div className="row mx--1">
                            <div className="col px-1">
                              <button
                                className={`btn btn-${this.state.selected_early_discount ? 'active' : 'enable'} w-100 h-100 py-1 px-1`}
                                onClick={() => this.toggleEarlyDiscount()}>
                                <p className="mb-0 font-weight-bold">早割対象</p>
                              </button>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderMenu() {
    return (
      <div className="row mx-0">
        <div className="col px-0">
          <div className="row mx-0">
            <div className="col py-2 text-center">
              <span>ご希望のメニューをお選びください</span>
            </div>
          </div>
          <div>
            <div className="row mx-0 px-3-env pt-2 pb-1">
              <div className="col py-1 px-1 align-self-center">
                <div className="row mx-0 mt-3 mb-3 text-center">
                  <div className="col px-0">
                    <Link to={`/?page=${constClass.PLAN}`}>
                      <button
                        className={`btn btn-enable w-100 h-100 py-2 px-2`}>
                        <h4 className="mb-0 font-weight-bold">ご注文</h4>
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="row mx-0 my-3 text-center">
                  <div className="col px-0">
                    <Link to={`/?page=${constClass.HISTORY}`}>
                      <button
                        className={`btn btn-enable w-100 h-100 py-2 px-2`}>
                        <h4 className="mb-0 font-weight-bold">ご注文履歴・変更</h4>
                      </button>
                    </Link>
                  </div>
                </div>
                {/* <div className="row mx-0 mt-0 mb-3 text-center">
                  <div className="col px-0">
                    {this.state.my_order.find(o => o.status === constClass.STATUS.UPAY) &&
                      <Link to={`/?page=${constClass.HISTORY}`}>
                        <span className="text-danger">お支払いが済んでいないご注文がございます</span>
                      </Link>
                    }
                  </div>
                </div> */}
                <div className="row mx-0 my-3 text-center">
                  <div className="col px-0">
                    <Link to={`/?page=${constClass.RECEIVESELECT}`}>
                      <button
                        className={`btn btn-enable w-100 h-100 py-2 px-2`}>
                        <h4 className="mb-0 font-weight-bold">お受け取り</h4>
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="row mx-0 my-3 text-center">
                  <div className="col px-0">
                    <Link to={`/?page=${constClass.USERCHANGE}`}>
                      <button
                        className={`btn btn-enable w-100 h-100 py-2 px-2`}>
                        <h4 className="mb-0 font-weight-bold">お客様情報</h4>
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="row mx-0 my-3 text-center">
                </div>
                <div className="row mx-0 my-1 text-center">
                  <div className="col px-0">
                    <a href={constClass.SPECIFIED_COMMERCIAL_URL} target="_blank" rel="noreferrer"><u>特定商取引法に関する表記</u></a>
                  </div>
                </div>
                <div className="row mx-0 my-3 text-center">
                  <div className="col px-0">
                    <a href={constClass.USER_GUIDE_URL} target="_blank" rel="noreferrer"><u>ご利用案内</u></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderPlan() {
    return (
      <div className="row mx-0">
        <div className="col px-0">
          <div>
            <div className="row mx-0 px-3-env pt-2 pb-1">
              <div className="col py-1 px-1 align-self-center">
                <div className="row mx-0 my-3 text-center">
                  <div className="col px-0">
                    ご予約承り中
                  </div>
                </div>
                <div className="bg-white border border-info rounded px-3">
                  {this.state.planMaster.map((plan, idx) =>
                    <div key={plan.plan_id} className="row mx-0 my-3">
                      <div className="col-12 px-0 text-left bg-white">
                        受付期間:{plan.plan_start.substr(4, 2)}/{plan.plan_start.substr(6, 2)}～{plan.plan_end.substr(4, 2)}/{plan.plan_end.substr(6, 2)}
                      </div>
                      <div className="col-12 px-0 text-left bg-white">
                        ・　{plan.plan_name}
                      </div>
                      {plan.pdf_url &&
                        <div className="col-12 px-0 text-right bg-white">
                          予約パンフレットは<a href={`${constClass.PLAN_IMAGE_DIR}${plan.pdf_url}`} target="_blank" rel="noreferrer"><u>こちら</u></a>
                        </div>
                      }
                      <div className="col-12 px-0 text-left bg-white text-danger">
                        {plan.payment_flag === constClass.FLAG.ON && '※事前決済が必要です。'}
                        {plan.payment_flag === constClass.FLAG.BOTH && '※事前決済可能です。'}
                        {plan.delivery_flag === constClass.FLAG.ON && '※配送専用です。'}
                        {plan.delivery_flag === constClass.FLAG.BOTH && '※配送可能です。'}
                      </div>
                    </div>
                  )}
                  {this.state.planMaster.length <= 0 &&
                    <div className="row mx-0 my-3">
                      <div className="col-12 px-0 text-left bg-white">
                        現在ご予約可能な商品はありません。
                      </div>
                    </div>
                  }
                </div>
                <div className="row mx-0 my-3 text-center">
                  <div className="col px-0">
                    画面の表示に従ってご注文ください。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderReceiveShop() {
    return (
      <div className="row mx-0">
        <div className="col px-0">
          <div className="row mx-0">
            <div className="col section py-0 line-height-2-2">
              <span className="d-inline-block align-middle">受け取り希望店舗　選択</span>
            </div>
          </div>
          <div>
            <div className="row mx-0 bg-white px-3-env pt-2 pb-1">
              <div className="col bg-white py-1 px-1 align-self-center">
                <div className="row mx-0 my-3 text-center">
                  <div className="col-6 px-0 bg-white text-center align-self-center">
                    地区で絞り込む
                  </div>
                  <div className="col-6 px-0 bg-white align-self-center">
                    <select className="custom-select w-100 text-center" aria-label="地区" value={this.state.area} onChange={(e) => this.setArea(e.target.value)} onBlur={() => this.scrollWindowTop()}>
                      <option value="">- 選択 -</option>
                      {this.state.areaMaster.map((item) =>
                        <option key={item.area_id} value={item.area_id}>{item.area_name}</option>
                      )}
                    </select>
                  </div>
                </div>
                <div className="row mx-0 my-3 text-center">
                  <div className="col px-0 bg-white">
                    <select className="custom-select w-100 text-center" aria-label="受取店舗" ref={this.receiveShopRef} value={this.state.receive_shop} onChange={(e) => this.setReceiveShop(e.target.value)} onBlur={() => this.scrollWindowTop()}>
                      <option value="" >- 選択 -</option>
                      {this.state.shopMaster.filter(s => s.shop_id > 0) // 本社を除く
                        .filter(s => !(this.state.area) || s.area_id === this.state.area) // 地区が選ばれていたら絞り込む
                        .filter(s => !(this.getDuplicateNgShop(this.state.productMaster).includes(s.shop_id))).map((item) => // 全ての商品で受取不可の店舗を除く
                          <option key={item.shop_id} value={item.shop_id}>{item.shop_name}</option>
                        )}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mx-0 bg-white px-3-env pt-1 pb-2">
              <div className="col">
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderRequest() {
    return (
      <div className="row mx-0">
        <div className="col px-0">
          {/* {this.state.productMaster.filter((item) => this.getDateString() <= item.order_limit) // 締め切り日フィルタ
            .filter((item) => (this.state.product_category.length <= 0 || this.state.product_category.includes(item.product_category_id))) // カテゴリ選択フィルタ
            .filter((item) => this.state.selected_plan.length <= 0 || this.state.selected_plan.includes(item.plan_id)) // 企画選択フィルタ
            .filter((item) => !this.state.selected_early_discount || this.checkEarlyDiscount(item)).map((item, idx) => // 早割対象フィルタ
              <div key={item.product_id}>
                <div className="row mx-0 bg-white px-3-env pt-2 pb-1">
                  <div className="col bg-white py-1 px-1 align-self-center">
                    <div className="row mx-0 text-center">
                      {item.product_image &&
                        <div className="col px-0 bg-white">
                          <img className="w-75" src={`${constClass.PRODUCT_IMAGE_DIR}${item.product_image}`} alt={item.product_name} />
                        </div>
                      }
                    </div>
                    {this.checkEarlyDiscount(item) &&
                      <div className="row mx-0 text-left">
                        <div className="col px-0 bg-white">
                          <span className="badge badge-warning p-1 align-middle">早割対象商品</span>
                          <span className="font-weight-normal px-2 align-middle">{this.getShortDateString(item.early_discount_end)}までのご注文で早割適用</span>
                        </div>
                      </div>
                    }
                    <div className="row mx-0 text-left">
                      <div className="col-9 px-0 bg-white">{item.product_name}</div>
                      <div className="col-3 px-0 bg-white text-right">&yen;{Math.floor(item.price_before_tax).toLocaleString()}<br /><small>&yen;{item.price.toLocaleString()}(税込)</small></div>
                    </div>
                    <div className="row mx-0 text-left">
                      <div className="col px-0 bg-white font-weight-normal">{item.product_detail}</div>
                    </div>
                    {item.point > 0 &&
                      <div className="row mx-0 text-left">
                        <div className="col px-0 bg-white text-danger">ご購入時にお買い物ポイント{item.point}pプレゼント！</div>
                      </div>
                    }
                    <div className="row mx-4">
                      <div className="col-auto text-center p-1 ml-1">
                        <button
                          disabled={((item.year_round_type!=="1" || item.stock>0) && (item.stock - item.order_count) <= 0 ) || this.state.disabled || item.m_ng_shop.map(s => s.shop_id).includes(this.state.my_customer.receive_shop)}
                          className={`btn btn-active w-100 h-100 py-0 px-2`}
                          onClick={() => { this.subCart(item.product_id) }}>
                          －
                        </button>
                      </div>
                      <div className="col text-center py-1 px-1 align-self-center">
                        <div className="bg-white border-textbox">
                          <h4 className="my-1">
                            {this.displayCartCount(item.plan_id, item.product_id)}
                          </h4>
                        </div>
                      </div>
                      <div className="col-auto text-center p-1 mr-1">
                        <button
                          disabled={((item.year_round_type!=="1" || item.stock>0) &&(item.stock - item.order_count) <= 0) || this.state.disabled || item.m_ng_shop.map(s => s.shop_id).includes(this.state.my_customer.receive_shop)}
                          className={`btn btn-active w-100 h-100 py-0 px-2`}
                          onClick={() => { this.addCart(item.product_id) }}>
                          ＋
                        </button>
                      </div>
                    </div>
                    <div className="row mx-0 text-center">
                      <div className="col px-0 bg-white font-weight-normal text-danger">{this.state.message[item.product_id]}</div>
                    </div>
                  </div>
                </div>
                <div className="row mx-0 bg-white px-3-env pt-1 pb-2">
                  <div className="col">
                  </div>
                </div>
              </div>
            )} */}
          <ProductList
            products={this.state.productMaster.filter((item) => this.getDateString() <= item.order_limit) // 締め切り日フィルタ
              .filter((item) => (this.state.product_category.length <= 0 || this.state.product_category.includes(item.product_category_id))) // カテゴリ選択フィルタ
              .filter((item) => this.state.selected_plan.length <= 0 || this.state.selected_plan.includes(item.plan_id)) // 企画選択フィルタ
              .filter((item) => !this.state.selected_early_discount || this.checkEarlyDiscount(item))}
            disabled={this.state.disabled}
            message={this.state.message}
            receive_shop={this.state.my_customer.receive_shop}
            displayCartCount={this.displayCartCount}
            addCart={this.addCart}
            subCart={this.subCart} />
        </div>
      </div>
    );
  }

  renderUser() {
    return (
      <div className="row mx-0">
        <div className="col px-0">
          <div className="row mx-0 my-3 text-center">
            <div className="col px-0">
              お受け取りの際に必要な情報となりますので<br />
              間違いの無いようご記入ください。
            </div>
          </div>
          <div>
            <div className="row mx-0 px-3-env pt-2 pb-1">
              <div className="col py-1 px-1 align-self-center">
                {!(this.props.page === constClass.USER && this.props.plan_id > 0) &&
                  <React.Fragment>
                    <div className="row mx-0 text-left form-group">
                      <div className="col-4 px-0"><label htmlFor="name">お名前</label></div>
                      <div className="col-8 px-0"><input type="text" className="form-control" id="name" name="name" value={this.state.name} onChange={this.change} onBlur={() => this.scrollWindowTop()}></input></div>
                    </div>
                    <div className="row mx-0 text-left form-group">
                      <div className="col-4 px-0"><label htmlFor="name">お名前(カナ)</label></div>
                      <div className="col-8 px-0"><input type="text" className="form-control" id="kana" name="kana" value={this.state.kana} onChange={this.change} onBlur={() => this.scrollWindowTop()}></input></div>
                    </div>
                    <div className="row mx-0 text-left">
                      <div className="col-4 px-0"><label htmlFor="tel">電話番号</label></div>
                      <div className="col-8 px-0"><input type="text" className="form-control" id="tel" name="tel" value={this.state.tel} onChange={this.change} onBlur={() => this.scrollWindowTop()}></input></div>
                    </div>
                    <div className="row mx-0 text-left form-group">
                      <div className="col px-0 text-danger font-weight-normal">{this.state.message.tel}</div>
                    </div>
                  </React.Fragment>
                }
                {((this.props.page === constClass.USER && this.state.planMaster.filter(p => this.state.cart.map(c => c.planId).includes(p.plan_id)).reduce((prev, p) => [constClass.FLAG.ON, constClass.FLAG.BOTH].includes(p.delivery_flag) ? true : prev, false)) || //注文時ユーザ登録
                  (this.props.page === constClass.ORDERUSERCHANGE && this.state.my_order.find(o => o.order_id === this.props.current_order).delivery_flag === constClass.FLAG.ON) || // 注文情報変更
                  (this.props.page === constClass.USERCHANGE)) && // お客様情報変更
                  <div>
                    {!(this.props.page === constClass.USER && this.props.plan_id > 0) &&
                      <React.Fragment>
                        <div className="row mx-0 text-center form-group">
                          <div className="col px-0">配送をご利用の場合はご記入ください</div>
                        </div>
                        <div className="row mx-0 text-left">
                          <div className="col-4 px-0"><label htmlFor="postal_code">郵便番号</label></div>
                          <div className="col-5 px-0"><input type="text" className="form-control" id="postal_code" name="postal_code" value={this.state.postal_code} onChange={this.change} onBlur={() => this.scrollWindowTop()}></input></div>
                          <div className="col-3 px-1">
                            <button
                              onClick={() => this.searchAddress(this.state.postal_code, '')}
                              className={`btn btn-enable w-90 py-1 px-1`}>
                              住所検索
                            </button>
                          </div>
                        </div>
                        <div className="row mx-0 text-left form-group">
                          <div className="col px-0 text-danger font-weight-normal">{this.state.message.postal_code}</div>
                        </div>
                        <div className="row mx-0 text-left form-group">
                          <div className="col-4 px-0"><label htmlFor="address">住所</label></div>
                          <div className="col-8 px-0"><input type="text" className="form-control" id="address" name="address" value={this.state.address} onChange={this.change} onBlur={() => this.scrollWindowTop()}></input></div>
                        </div>
                      </React.Fragment>
                    }
                    {(this.props.page !== constClass.USERCHANGE && (isNaN(this.props.plan_id) || this.props.plan_id > 0)) &&
                      <React.Fragment>
                        {!(this.props.page === constClass.USER && this.props.plan_id > 0) &&
                          <div className="row mx-0 text-left form-group">
                            <div className="col-auto px-0 align-self-center"><label htmlFor="delivery_check" className="mb-0">配送先は上記と同じ</label></div>
                            <div className="col-2"><input type="checkbox" className="form-control" id="delivery_check" name="delivery_check" value="true" checked={this.state.delivery_check} onChange={this.change}></input></div>
                            <div className="col"></div>
                          </div>
                        }
                        <div className="row mx-0 text-left form-group">
                          <div className="col-4 px-0"><label htmlFor="name">配送先お名前</label></div>
                          <div className="col-8 px-0"><input type="text" className="form-control" id="delivery_name" name="delivery_name" value={this.state.delivery_name} disabled={this.state.delivery_check} onChange={this.change} onBlur={() => this.scrollWindowTop()}></input></div>
                        </div>
                        <div className="row mx-0 text-left form-group">
                          <div className="col-4 px-0"><label htmlFor="name">配送先お名前(カナ)</label></div>
                          <div className="col-8 px-0"><input type="text" className="form-control" id="delivery_kana" name="delivery_kana" value={this.state.delivery_kana} disabled={this.state.delivery_check} onChange={this.change} onBlur={() => this.scrollWindowTop()}></input></div>
                        </div>
                        <div className="row mx-0 text-left">
                          <div className="col-4 px-0"><label htmlFor="tel">配送先電話番号</label></div>
                          <div className="col-8 px-0"><input type="text" className="form-control" id="delivery_tel" name="delivery_tel" value={this.state.delivery_tel} disabled={this.state.delivery_check} onChange={this.change} onBlur={() => this.scrollWindowTop()}></input></div>
                        </div>
                        <div className="row mx-0 text-left form-group">
                          <div className="col px-0 text-danger font-weight-normal">{this.state.message.delivery_tel}</div>
                        </div>
                        <div className="row mx-0 text-left">
                          <div className="col-4 px-0"><label htmlFor="postal_code">配送先郵便番号</label></div>
                          <div className="col-5 px-0"><input type="text" className="form-control" id="delivery_postal_code" name="delivery_postal_code" value={this.state.delivery_postal_code} disabled={this.state.delivery_check} onChange={this.change} onBlur={() => this.scrollWindowTop()}></input></div>
                          <div className="col-3 px-1">
                            <button
                              onClick={() => this.searchAddress(this.state.delivery_postal_code, 'delivery_')}
                              className={`btn btn-enable w-90 py-1 px-1`}>
                              住所検索
                            </button>
                          </div>
                        </div>
                        <div className="row mx-0 text-left form-group">
                          <div className="col px-0 text-danger font-weight-normal">{this.state.message.delivery_postal_code}</div>
                        </div>
                        <div className="row mx-0 text-left form-group">
                          <div className="col-4 px-0"><label htmlFor="address">配送先住所</label></div>
                          <div className="col-8 px-0"><input type="text" className="form-control" id="delivery_address" name="delivery_address" value={this.state.delivery_address} disabled={this.state.delivery_check} onChange={this.change} onBlur={() => this.scrollWindowTop()}></input></div>
                        </div>
                      </React.Fragment>
                    }
                  </div>
                }
                <div className="row mx-0 my-1 text-left">
                  <div className="col px-0 font-weight-normal">
                    ※ご連絡が取れない場合はご予約をキャンセルさせて頂く場合がございます
                  </div>
                </div>
              </div>
            </div>
            <div className="row mx-0 px-3-env pt-2 pb-1 line-height-2-2">
              <div className="col-2">
                <input type="checkbox" className="form-control" id="privacypolicy" name="privacypolicy" value="true" checked={this.state.privacypolicy} onChange={this.change}></input>
              </div>
              <div className="col-10">
                <label htmlFor="privacypolicy" ><a href={constClass.PRIVACYPOLICY_URL} target="_blank" rel="noreferrer"><u>プライバシーポリシー</u></a>に同意する</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderConfirm() {
    return (
      <div className="row mx-0">
        <div className="col px-0">
          <div className="">
            <div className="row mx-0 px-3-env pt-2 pb-1">
              <div className="col-10 bg-white py-2 px-1 align-self-center border-textbox rounded">
                <div className="row mx-0 text-left">
                  <div className="col-4 px-0 bg-white">お名前</div>
                  <div className="col-8 px-0 bg-white">{this.state.my_customer.customer_name}</div>
                </div>
                <div className="row mx-0 text-left">
                  <div className="col-4 px-0 bg-white">お名前(カナ)</div>
                  <div className="col-8 px-0 bg-white">{this.state.my_customer.customer_kana}</div>
                </div>
                <div className="row mx-0 text-left">
                  <div className="col-4 px-0 bg-white">電話番号</div>
                  <div className="col-8 px-0 bg-white">{this.state.my_customer.customer_tel}</div>
                </div>
                {this.state.planMaster.filter(p => this.state.cart.map(c => c.planId).includes(p.plan_id)).reduce((prev, p) => [constClass.FLAG.ON, constClass.FLAG.BOTH].includes(p.delivery_flag) ? true : prev, false) &&
                  <div>
                    <div className="row mx-0 text-left">
                      <div className="col-4 px-0 bg-white">郵便番号</div>
                      <div className="col-8 px-0 bg-white">{this.state.my_customer.customer_postal_code}</div>
                    </div>
                    <div className="row mx-0 text-left">
                      <div className="col-4 px-0 bg-white">住所</div>
                      <div className="col-8 px-0 bg-white">{this.state.my_customer.customer_address}</div>
                    </div>
                  </div>
                }
              </div>
              <div className="col-2 py-1 px-1 align-self-center text-nowrap">
                <Link to={`/?page=${constClass.USER}&plan_id=0`} >
                  <button
                    className={`btn btn-enable w-90 py-2 px-2`}>
                    変更
                  </button>
                </Link>
              </div>
            </div>
            <div className="row mx-0 px-3-env pt-1 pb-1">
              <div className="col-10 bg-white py-2 px-1 align-self-center border-textbox rounded">
                <div className="row mx-0 text-left">
                  <div className="col-4 px-0 bg-white">受取店舗</div>
                  <div className="col-8 px-0 bg-white">{this.state.shopMaster.find(s => s.shop_id === this.state.my_customer.receive_shop).shop_name}</div>
                </div>
              </div>
              <div className="col-2 bg-white py-1 px-1 align-self-center text-nowrap">
                <Link to={`/?page=${constClass.PLAN}`} >
                  <button
                    className={`btn btn-enable w-90 py-2 px-2`}>
                    変更
                  </button>
                </Link>
              </div>
            </div>
            <div className="row mx-0 px-3-env pt-1 pb-1">
              <div className="col-12 text-center">
                ご注文内容
              </div>
            </div>
            {this.state.cart.filter(c => c.payment_flag === constClass.FLAG.ON).length > 1 &&
              <div className="row mx-0 px-1-env pt-1 pb-1">
                <div className="col-12 text-center text-danger">
                  複数回のお支払いが必要となります。<br />
                  お支払い完了後、続けてお支払いをお願いします。
                </div>
              </div>
            }
            {this.state.cart.map(c => c.planId).filter((elem, index, self) => self.indexOf(elem) === index).map((plan) =>
              <div key={plan} className="mb-2 p-2 bg-white border border-info rounded mx-2">
                <div className="row mx-0 px-1-env">
                  <div className="col py-1 px-1 align-self-center border-bottom">
                    <div className="row mx-0 text-left">
                      <div className="col px-0">◇{this.state.planMaster.find(p => p.plan_id === plan).plan_name}
                      </div>
                    </div>
                    {this.state.planMaster.find(p => p.plan_id === plan).payment_flag === constClass.FLAG.BOTH &&
                      <div>
                        <div className="row mx-0 px-2 mt-2">
                          <div className="col">
                            <p className="mb-0 text-danger">お支払い方法を選択してください</p>
                          </div>
                        </div>
                        <div className="row mx-0 px-2 mb-2">
                          <div className="col-2 align-self-center">
                            <input type="radio" className="form-control" id="payment_off" name="payment_flag" onChange={() => this.setCartFlag(plan, "payment_flag", constClass.FLAG.OFF)} value={constClass.FLAG.OFF} checked={this.state.cart.find(c => c.planId === plan).payment_flag === constClass.FLAG.OFF} />
                          </div>
                          <div className="col-4 align-self-center px-0">
                            <label htmlFor="payment_off" className="mb-0">受取時支払</label>
                          </div>
                          <div className="col-2 align-self-center">
                            <input type="radio" className="form-control" id="payment_on" name="payment_flag" onChange={() => this.setCartFlag(plan, "payment_flag", constClass.FLAG.ON)} value={constClass.FLAG.ON} checked={this.state.cart.find(c => c.planId === plan).payment_flag === constClass.FLAG.ON} />
                          </div>
                          <div className="col-4 align-self-center px-0">
                            <label htmlFor="payment_on" className="mb-0">事前支払</label>
                          </div>
                        </div>
                      </div>
                    }
                    {this.state.planMaster.find(p => p.plan_id === plan).delivery_flag === constClass.FLAG.BOTH &&
                      <div>
                        <div className="row mx-0 px-2 mt-2">
                          <div className="col">
                            <p className="mb-0 text-danger">受取方法を選択してください</p>
                          </div>
                        </div>
                        <div className="row mx-0 px-2 mb-2">
                          <div className="col-2 align-self-center">
                            <input type="radio" className="form-control" id="delivery_off" name="delivery_flag" onChange={() => this.setCartFlag(plan, "delivery_flag", constClass.FLAG.OFF)} value={constClass.FLAG.OFF} checked={this.state.cart.find(c => c.planId === plan).delivery_flag === constClass.FLAG.OFF} />
                          </div>
                          <div className="col-4 align-self-center px-0">
                            <label htmlFor="delivery_off" className="mb-0">店舗受取</label>
                          </div>
                          <div className="col-2 align-self-center">
                            <input type="radio" className="form-control" id="delivery_on" name="delivery_flag" onChange={() => this.setCartFlag(plan, "delivery_flag", constClass.FLAG.ON)} value={constClass.FLAG.ON} checked={this.state.cart.find(c => c.planId === plan).delivery_flag === constClass.FLAG.ON} />
                          </div>
                          <div className="col-4 align-self-center px-0">
                            <label htmlFor="delivery_on" className="mb-0">配送</label>
                          </div>
                        </div>
                      </div>
                    }
                    {this.state.cart.find(c => c.planId === plan).payment_flag === constClass.FLAG.ON &&
                      <div className="row mx-0 px-2 mt-2 mb-2">
                        <div className="col">
                          <p className="mb-0 text-danger">お支払いの完了後、注文が確定されます</p>
                        </div>
                      </div>
                    }
                    <div className="row mx-0 px-2 mt-2 mb-2">
                      <div className="col">
                        <div className="row pb-2">
                          <div className="col-5 align-self-center">
                            受取日
                          </div>
                          <div className="col-7 order-datepicker">
                            {this.state.planMaster.find(p => p.plan_id.toString() === plan.toString()).year_round_type === "1" &&
                              <DatePicker className="custom-select w-100 text-center border-textbox text-primary font-weight-bold" aria_labal="受取日" locale="ja" dateFormat="yyyy/MM/dd" monthsShown="1" id="receiveDate" name="receiveDate"
                                popperPlacement='bottom'
                                popperModifiers={{
                                  flip: {
                                    behavior: ["bottom"] // don't allow it to flip to be above
                                  },
                                }}
                                selected={this.state.cart.find(c => c.planId === plan).receiveDate ? new Date(this.state.cart.find(c => c.planId === plan).receiveDate.replace(/-/g, '/')) : null}
                                minDate={new Date(moment().add(parseInt(this.state.productMaster.filter(p => this.state.cart.find(c => c.planId === plan).detail.map(d => d.productId).includes(p.product_id)).sort((a, b) => parseInt(b.receipt_limit_days) - parseInt(a.receipt_limit_days))[0].receipt_limit_days), 'days').format('YYYY/MM/DD'))}
                                onChange={date => this.setReceiveDate(plan, date ? moment(new Date(date)).format('YYYYMMDD') : null)}
                                onBlur={() => this.scrollWindowTop()}
                                placeholderText="- 選択 -"
                                customInput={<button>{this.state.cart.find(c => c.planId === plan).receiveDate ? this.state.cart.find(c => c.planId === plan).receiveDate.replace(/-/g, '/') : "- 選択 -"}</button>}
                              />
                              /* <input type="date" className="w-100 text-left border-textbox text-primary font-weight-bold py-1" aria_labal="受取日" 
                                min={moment().add(parseInt(this.state.productMaster.filter(p => this.state.cart.find(c => c.planId === plan).detail.map(d => d.productId).includes(p.product_id)).sort((a,b)=> parseInt(b.receipt_limit_days) - parseInt(a.receipt_limit_days))[0].receipt_limit_days), 'days').format('YYYY-MM-DD')}
                                onChange={(e) => this.setReceiveDate(plan, e.target.value?moment(e.target.value).format('YYYYMMDD'):null)}
                                placeholder="年月日を数字8桁で入力"
                              />*/
                            }
                            {this.state.planMaster.find(p => p.plan_id.toString() === plan.toString()).year_round_type !== "1" &&
                              <select className="custom-select w-100 text-center border-textbox text-primary font-weight-bold" aria-label="受取日"
                                value={this.state.cart.find(c => c.planId === plan).receiveDate ? this.state.cart.find(c => c.planId === plan).receiveDate.replace(/-/g, '') : ''}
                                onChange={(e) => this.setReceiveDate(plan, e.target.value)} onBlur={() => this.scrollWindowTop()}>
                                <option value="">- 選択 -</option>
                                {this.getDuplicateReceiveDate(this.state.productMaster.filter(p => this.state.cart.find(c => c.planId === plan).detail.map(d => d.productId).includes(p.product_id))) //productMasterをcartに含まれるものでフィルタして関数に渡す
                                  .map(item =>
                                    <option key={item} value={item}>{item.substr(0, 4) + '/' + item.substr(4, 2) + '/' + item.substr(6, 2)}</option>
                                  )}
                              </select>
                            }
                          </div>
                        </div>
                        {this.state.cart.find(c => c.planId === plan).delivery_flag === constClass.FLAG.OFF &&
                          <div className="row pb-2">
                            <div className="col-5 align-self-center">
                              受取時間帯
                            </div>
                            <div className="col-7">
                              <select className="custom-select w-100 text-center border-textbox text-primary font-weight-bold" aria-label="受取時間帯" value={this.state.cart.find(c => c.planId === plan).receiveTime ? this.state.cart.find(c => c.planId === plan).receiveTime : ''} onChange={(e) => this.setReceiveTime(plan, e.target.value)} onBlur={() => this.scrollWindowTop()}>
                                <option value="">- 選択 -</option>
                                <option value={constClass.RECEIVE_TIME.AM}>{constClass.RECEIVE_TIME_NAME[constClass.RECEIVE_TIME.AM]}</option>
                                <option value={constClass.RECEIVE_TIME.PM12}>{constClass.RECEIVE_TIME_NAME[constClass.RECEIVE_TIME.PM12]}</option>
                                <option value={constClass.RECEIVE_TIME.PM14}>{constClass.RECEIVE_TIME_NAME[constClass.RECEIVE_TIME.PM14]}</option>
                                <option value={constClass.RECEIVE_TIME.PM16}>{constClass.RECEIVE_TIME_NAME[constClass.RECEIVE_TIME.PM16]}</option>
                                <option value={constClass.RECEIVE_TIME.PM18}>{constClass.RECEIVE_TIME_NAME[constClass.RECEIVE_TIME.PM18]}</option>
                              </select>
                            </div>
                          </div>
                        }
                        {this.state.cart.find(c => c.planId === plan).delivery_flag === constClass.FLAG.ON &&
                          <div className="row pb-2">
                            <div className="col-3 align-self-center">
                              配送先
                            </div>
                            <div className="col-7 px-0 align-self-center">
                              <small>{this.state.cart.find(c => c.planId === plan).delivery_kana}<br /></small>
                              {this.state.cart.find(c => c.planId === plan).delivery_name}<br />
                              &#x2121;{this.state.cart.find(c => c.planId === plan).delivery_tel}<br />
                              &#x3012;{this.state.cart.find(c => c.planId === plan).delivery_postal_code}<br />
                              {this.state.cart.find(c => c.planId === plan).delivery_address}
                            </div>
                            <div className="col-2 align-self-center text-nowrap">
                              <Link to={`/?page=${constClass.USER}&plan_id=${plan}`} >
                                <button
                                  className={`btn btn-enable w-90 py-2 px-2`}>
                                  変更
                                </button>
                              </Link>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                    {this.state.cart.find(c => c.planId === plan).detail.map((item) =>
                      <div key={item.productId} className="m-2 mb-1">
                        <div className="row mx-0 text-left">
                          <div className="col px-0">{this.state.productMaster.find(p => p.product_id === item.productId).product_name}</div>
                        </div>
                        <div className="row mx-0 text-right  align-self-center">
                          <div className="col-5 px-0 align-self-center">&yen;{Math.floor(item.price_before_tax).toLocaleString()}<br /><small>&yen;{item.price.toLocaleString()}(税込)</small></div>
                          <div className="col-7">
                            <div className="row">
                              <div className="col-auto text-center p-1 ml-1 align-self-center">
                                <button
                                  disabled={((item.year_round_type !== "1" || item.stock > 0) && (item.stock - item.order_count) <= 0) || this.state.disabled}
                                  className={`btn btn-active w-100 h-100 py-1 px-2`}
                                  onClick={() => { this.subCart(item.productId) }}>
                                  －
                                </button>
                              </div>
                              <div className="col text-center py-1 px-1 align-self-center border-textbox">
                                <div className="bg-white">{item.count}</div>
                              </div>
                              <div className="col-auto text-center p-1 mr-1 align-self-center">
                                <button
                                  disabled={((item.year_round_type !== "1" || item.stock > 0) && (item.stock - item.order_count) <= 0) || this.state.disabled}
                                  className={`btn btn-active w-100 h-100 py-1 px-2`}
                                  onClick={() => { this.addCart(item.productId) }}>
                                  ＋
                                </button>
                              </div>
                              <div className="col-auto align-self-center pl-1 pr-0">個</div>
                            </div>
                          </div>
                        </div>
                        <div className="row mx-0 text-right  align-self-center">
                          <div className="col px-0 text-danger">{this.state.message[item.productId]}</div>
                          <div className="col-auto px-0">
                            <button
                              className={`btn btn-enable w-100 py-0 px-2`}
                              onClick={() => { this.deleteCart(item.productId) }}>
                              削除
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row mx-0 px-1-env pt-1 pb-2">
                  <div className="col">
                    <div className="row">
                      <div className="col-6 text-right">
                        小計
                      </div>
                      <div className="col-6 text-right">
                        &yen;{(this.getOrderTotal(this.state.cart.find(c => c.planId === plan)).subtotal).toLocaleString()}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 text-right">
                        税額
                      </div>
                      <div className="col-6 text-right">
                        &yen;{(this.getOrderTotal(this.state.cart.find(c => c.planId === plan)).tax).toLocaleString()}
                      </div>
                    </div>
                    {this.getOrderTotal(this.state.cart.find(c => c.planId === plan)).discount > 0 &&
                      <div className="row">
                        <div className="col-6 text-right">
                          税込金額
                        </div>
                        <div className="col-6 text-right">
                          &yen;{(this.getOrderTotal(this.state.cart.find(c => c.planId === plan)).subtotal + this.getOrderTotal(this.state.cart.find(c => c.planId === plan)).tax).toLocaleString()}
                        </div>
                      </div>
                    }
                    {this.getOrderTotal(this.state.cart.find(c => c.planId === plan)).discount > 0 &&
                      <div className="row">
                        <div className="col-6 text-right">
                          早期割引
                        </div>
                        <div className="col-6 text-right">
                          &yen;{(this.getOrderTotal(this.state.cart.find(c => c.planId === plan)).discount * -1).toLocaleString()}
                        </div>
                      </div>
                    }
                    <div className="row">
                      <div className="col-6 text-right">
                        合計
                      </div>
                      <div className="col-6 text-right">
                        &yen;{(this.getOrderTotal(this.state.cart.find(c => c.planId === plan)).total).toLocaleString()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderThanks() {
    return (
      <div className="row mx-0">
        <div className="col px-0">
          <div>
            <div className="row mx-0 px-3-env pt-2 pb-1">
              <div className="col bg-lightblue py-1 px-1 align-self-center border-textbox rounded">
                <div className="row mx-0 text-center">
                  <div className="col px-0"><h4>THANK YOU!</h4></div>
                </div>
                {this.props.page === constClass.ORDERFINISH &&
                  <div className="row mx-0 text-center">
                    <div className="col px-0"><h4>ご注文を承りました。</h4></div>
                  </div>
                }
                {this.props.page === constClass.CHANGEFINISH &&
                  <div className="row mx-0 text-center">
                    <div className="col px-0"><h4>ご注文情報の変更を承りました。</h4></div>
                  </div>
                }
                {this.props.page === constClass.RECEIVEFINISH &&
                  <div className="row mx-0 text-center">
                    <div className="col-12 px-0"><h4>ご注文ありがとうございました。</h4></div>
                    <div className="col-12 px-0"><h4>またのご利用をお待ちしております。</h4></div>
                  </div>
                }
                {this.props.page === constClass.MEMBERFINISH &&
                  <div className="row mx-0 text-center">
                    <div className="col px-0"><h4>ご登録ありがとうございました。</h4></div>
                  </div>
                }
                {(this.props.page === constClass.ORDERFINISH || this.props.page === constClass.CHANGEFINISH) &&
                  <div className="row mx-0 text-center">
                    <div className="col px-0">注文番号 {this.state.order_finish && this.state.order_finish.map((item) => "No." + item.receipt_num)}{(!this.state.order_finish && this.props.current_order) && 'No.' + this.state.my_order.find(o => o.order_id === this.props.current_order).receipt_num}</div>
                  </div>
                }
                <div className="row mx-0 text-center">
                  <div className="col px-0"><img src={Thanks} alt="ありがとうございました。" /></div>
                </div>
              </div>
            </div>
            {(this.props.page === constClass.ORDERFINISH || this.props.page === constClass.CHANGEFINISH) &&
              <div>
                <div className="row mx-0 px-3-env pt-1 pb-2">
                  <div className="col">
                    {this.state.my_order.find(o => o.order_id === this.props.current_order) &&
                      <React.Fragment>
                        {this.state.my_order.find(o => o.order_id === this.props.current_order).delivery_flag === constClass.FLAG.ON
                          ? '選択された日にお届けします。' // 配送フラグオン
                          : '選択された日時・店舗にお受け取りにお越しください。'
                        }
                      </React.Fragment>
                    }
                    {(!this.state.my_order.find(o => o.order_id === this.props.current_order) && (this.state.order_finish && this.state.order_finish.reduce((prev, item) => prev || item.delivery_flag !== constClass.FLAG.ON, false))) &&
                      <div>
                        選択された日時・店舗にお受け取りにお越しください。
                      </div>
                    }
                    {(!this.state.my_order.find(o => o.order_id === this.props.current_order) && (this.state.order_finish && this.state.order_finish.reduce((prev, item) => prev || item.delivery_flag === constClass.FLAG.ON, false))) &&
                      <div>
                        選択された日にお届けします。
                      </div>
                    }
                  </div>
                </div>
                {/* {(!this.state.order_finish && !isNaN(this.props.current_order)) &&
                  <div>
                    <div className="row mx-0">
                      <div className="col section py-0 line-height-2-2">
                        <span className="d-inline-block align-middle">クレジットカード情報を登録する場合はこちら</span>
                      </div>
                    </div>
                    <div className="row mx-0 px-3-env pt-1 pb-2">
                      <div className="col">
                        <button
                          className={`btn btn-enable w-100 h-100 py-2 px-2`}
                          onClick={() => this.submitCardEdit()}>
                          <h4 className="mb-0 font-weight-bold">登録</h4>
                        </button>
                      </div>
                    </div>
                  </div>
                } */}
                {this.state.my_order.find(o => o.status === constClass.STATUS.UPAY) &&
                  <div>
                    <div className="row mx-0">
                      <div className="col py-0 text-center text-danger">
                        <span className="d-inline-block align-middle">未決済のご注文がございますので続けてお支払いをお願いします。</span>
                      </div>
                    </div>
                    {this.state.my_order.filter(o => ([constClass.STATUS.UPAY].includes(o.status))).length > 0 && // 履歴の場合に未決済があれば当セクションを表示
                      <div className="row mx-0 px-3-env">
                        <div className="col py-0 px-1 align-self-center">
                          {this.state.my_order.filter(o => ([constClass.STATUS.UPAY].includes(o.status))) //未決済のみ
                            .sort((a, b) => ((a.plan_id === b.plan_id) ? b.order_id - a.order_id : b.plan_id - a.plan_id)).map((order) =>
                              this.renderOrderSelectdetail(order)
                            )}
                        </div>
                      </div>
                    }
                    {/* <div className="row mx-0 px-3-env pt-1 pb-2">
                      <div className="col">
                        <Link to={`/?page=${constClass.HISTORY}`}>
                          <button
                            className={`btn btn-submit-alert w-100 h-100 py-2 px-2`}>
                            <h4 className="mb-0 font-weight-bold">ご注文履歴・変更</h4>
                          </button>
                        </Link>
                      </div>
                    </div> */}
                  </div>
                }
                {!this.state.my_order.find(o => o.status === constClass.STATUS.UPAY) &&
                  <div>
                    <div className="row mx-0">
                      <div className="col section py-0 line-height-2-2">
                        <span className="d-inline-block align-middle">ご注文履歴のご確認・変更はこちら</span>
                      </div>
                    </div>
                    <div className="row mx-0 px-3-env pt-1 pb-2">
                      <div className="col">
                        <Link to={`/?page=${constClass.HISTORY}`}>
                          <button
                            className={`btn btn-enable w-100 h-100 py-2 px-2`}>
                            <h4 className="mb-0 font-weight-bold">ご注文履歴・変更</h4>
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                }
              </div>
            }
            {(this.props.page === constClass.RECEIVEFINISH) &&
              <div>
                {constClass.STORE_URL !== null &&
                  <div className="row mx-0 px-3-env pt-1 pb-2">
                    <div className="col text-center">
                      <a href={`${constClass.STORE_URL}?openExternalBrowser=1`} target="_blank" rel="noreferrer">
                        <button
                          disabled={this.state.disabled}
                          className={`btn-lg btn-alert w-100 py-3 p-env-bottom`}>
                          <span>{constClass.STORE_URL_LINKSTR}</span>
                        </button>
                      </a>
                    </div>
                  </div>
                }
                <div className="row mx-0">
                  <div className="col section py-0 line-height-2-2">
                    <span className="d-inline-block align-middle">その他の商品をお受け取りの際はこちら</span>
                  </div>
                </div>
                <div className="row mx-0 px-3-env pt-1 pb-2">
                  <div className="col">
                    <Link to={`/?page=${constClass.RECEIVESELECT}`}>
                      <button
                        className={`btn btn-enable w-100 h-100 py-2 px-2`}>
                        <h4 className="mb-0 font-weight-bold">お受け取り</h4>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }

  renderChange() {
    return (
      <div className="row mx-0">
        <div className="col px-0">
          <div>
            <div className="row mx-0 px-3-env pt-2 pb-1">
              <div className="col-10 py-2 px-1 bg-white align-self-center border-textbox rounded">
                <div className="row mx-0 text-left">
                  <div className="col-4 px-0">お名前</div>
                  <div className="col-8 px-0">{this.state.my_order.find(o => o.order_id === this.props.current_order).customer_name}</div>
                </div>
                <div className="row mx-0 text-left">
                  <div className="col-4 px-0">お名前(カナ)</div>
                  <div className="col-8 px-0">{this.state.my_order.find(o => o.order_id === this.props.current_order).customer_kana}</div>
                </div>
                <div className="row mx-0 text-left">
                  <div className="col-4 px-0">電話番号</div>
                  <div className="col-8 px-0">{this.state.my_order.find(o => o.order_id === this.props.current_order).customer_tel}</div>
                </div>
                {this.state.my_order.find(o => o.order_id === this.props.current_order).delivery_flag === constClass.FLAG.ON &&
                  <div>
                    <div className="row mx-0 text-left">
                      <div className="col-4 px-0 bg-white">郵便番号</div>
                      <div className="col-8 px-0 bg-white">{this.state.my_order.find(o => o.order_id === this.props.current_order).customer_postal_code}</div>
                    </div>
                    <div className="row mx-0 text-left">
                      <div className="col-4 px-0 bg-white">配送先住所</div>
                      <div className="col-8 px-0 bg-white">{this.state.my_order.find(o => o.order_id === this.props.current_order).customer_address}</div>
                    </div>
                  </div>
                }
              </div>
              <div className="col-2 py-1 px-1 align-self-center">
                <button
                  className={`btn btn-enable w-100 py-2 px-2`}
                  onClick={() => this.moveOrderCustomer()}>
                  変更
                </button>
              </div>
            </div>
            <div className="row mx-0 px-3-env pt-2 pb-1">
              <div className="col-10 bg-white py-2 px-1 align-self-center border-textbox rounded">
                <div className="row mx-0 text-left">
                  <div className="col-4 px-0">受取店舗</div>
                  <div className="col-8 px-0">{this.state.shopMaster.find(s => s.shop_id === this.state.my_order.find(o => o.order_id === this.props.current_order).receive_shop).shop_name}</div>
                </div>
              </div>
              <div className="col-2 py-1 px-1 align-self-center">
                <button
                  className={`btn btn-enable w-100 py-2 px-2`}
                  onClick={() => this.moveOrderShop()}>
                  変更
                </button>
              </div>
            </div>
            {this.state.my_order.find(o => o.order_id === this.props.current_order).status === constClass.STATUS.UPAY &&
              <div>
                <div className="row mx-0 pt-1">
                  <div className="col text-center">
                    <p className="mb-0 text-danger">お支払いが完了するまで注文は確定されません</p>
                  </div>
                </div>
                <div className="row mx-0 pb-2">
                  <div className="col py-0 px-5 align-self-center">
                    <button
                      className={`btn btn-submit-alert w-100 py-2 px-2`}
                      onClick={() => this.submitPayment(this.props.current_order)}>
                      決済画面へ
                    </button>
                  </div>
                </div>
              </div>
            }
            <div className="row mx-0 px-3-env pt-1 pb-2">
              <div className="col text-center">
                ご注文内容
              </div>
            </div>
            {this.state.my_order.filter(o => o.order_id === this.props.current_order).map((order) =>
              <div key={order.order_id} className="mb-2 p-2 bg-white border border-info rounded mx-2">
                <div className="row mx-0 px-1-env">
                  <div className="col py-1 px-1 align-self-center border-bottom">
                    <div className="row mx-0 text-left">
                      <div className="col px-0">{this.state.planMaster.find(p => p.plan_id === order.plan_id).plan_name}</div>
                    </div>
                    <div className="row mx-0 px-2 mt-2 mb-2">
                      <div className="col">
                        <div className="row pb-2">
                          <div className="col-5 align-self-center">
                            受取日
                          </div>
                          <div className="col-7 order-datepicker">
                            {this.state.planMaster.find(p => p.plan_id === order.plan_id).year_round_type === "1" &&
                              <DatePicker className="custom-select w-100 text-center border-textbox text-primary font-weight-bold" aria_labal="受取日" locale="ja" dateFormat="yyyy/MM/dd" monthsShown="1" id="receiveDate" name="receiveDate"
                                popperPlacement='bottom'
                                popperModifiers={{
                                  flip: {
                                    behavior: ["bottom"] // don't allow it to flip to be above
                                  },
                                }}
                                selected={order.receive_date ? new Date(order.receive_date.replace(/-/g, '/')) : null}
                                minDate={this.getMinDateReceiveDate(order.receive_date, this.state.productMaster.filter(p => order.t_order_detail.map(d => d.product_id).includes(p.product_id)))}
                                onChange={date => this.setOrderReceiveDate(order.order_id, date ? moment(date).format('YYYYMMDD') : null)}
                                placeholderText="- 選択 -"
                                customInput={<button>{order.receive_date ? order.receive_date.replace(/-/g, '/') : "- 選択 -"}</button>}
                              />
                              /*<input type="date" id="receiveDate" name="receiveDate" className="w-100 text-left border-textbox text-primary font-weight-bold py-1" aria_labal="受取日"
                                value={order.receive_date ? order.receive_date : null}
                                min={moment(this.getMinDateReceiveDate(order.receive_date, this.state.productMaster.filter(p => order.t_order_detail.map(d => d.product_id).includes(p.product_id)))).format('YYYY-MM-DD')}
                                onChange={(e) => this.setOrderReceiveDate(order.order_id, e.target.value ? moment(e.target.value).format('YYYYMMDD') : null)}
                                placeholder="年月日を数字8桁で入力"
                              />*/
                            }
                            {this.state.planMaster.find(p => p.plan_id === order.plan_id).year_round_type !== "1" &&
                              <select className="custom-select w-100 text-center border-textbox text-primary font-weight-bold" aria-label="受取日" value={order.receive_date ? order.receive_date.replace(/-/g, '') : ''} onChange={(e) => this.setOrderReceiveDate(order.order_id, e.target.value)}>
                                <option value="">- 選択 -</option>
                                {this.getDuplicateReceiveDate(this.state.productMaster.filter(p => order.t_order_detail.map(d => d.product_id).includes(p.product_id))) //productMasterをcartに含まれるものでフィルタして関数に渡す
                                  .map(item => // receive_dateの配列に変換→重複除外→オプションのDOM要素に変換
                                    <option key={item} value={item}>{item.substr(0, 4) + '/' + item.substr(4, 2) + '/' + item.substr(6, 2)}</option>
                                  )}
                              </select>
                            }
                          </div>
                        </div>
                        {order.delivery_flag !== constClass.FLAG.ON &&
                          <div className="row pb-2">
                            <div className="col-5 align-self-center">
                              受取時間帯
                            </div>
                            <div className="col-7">
                              <select className="custom-select w-100 text-center border-textbox text-primary font-weight-bold" aria-label="受取時間帯" value={order.receive_time} onChange={(e) => this.setOrderReceiveTime(order.order_id, e.target.value)}>
                                <option value="">- 選択 -</option>
                                <option value={constClass.RECEIVE_TIME.AM}>{constClass.RECEIVE_TIME_NAME[constClass.RECEIVE_TIME.AM]}</option>
                                <option value={constClass.RECEIVE_TIME.PM12}>{constClass.RECEIVE_TIME_NAME[constClass.RECEIVE_TIME.PM12]}</option>
                                <option value={constClass.RECEIVE_TIME.PM14}>{constClass.RECEIVE_TIME_NAME[constClass.RECEIVE_TIME.PM14]}</option>
                                <option value={constClass.RECEIVE_TIME.PM16}>{constClass.RECEIVE_TIME_NAME[constClass.RECEIVE_TIME.PM16]}</option>
                                <option value={constClass.RECEIVE_TIME.PM18}>{constClass.RECEIVE_TIME_NAME[constClass.RECEIVE_TIME.PM18]}</option>
                              </select>
                            </div>
                          </div>
                        }
                        {order.delivery_flag === constClass.FLAG.ON &&
                          <div className="row pb-2">
                            <div className="col-3 align-self-center">
                              配送先
                            </div>
                            <div className="col-7 px-0 align-self-center">
                              <small>{order.delivery_kana}<br /></small>
                              {order.delivery_name}<br />
                              &#x2121;{order.delivery_tel}<br />
                              &#x3012;{order.delivery_postal_code}<br />
                              {order.delivery_address}
                            </div>
                            <div className="col-2 align-self-center text-nowrap">
                              <button
                                className={`btn btn-enable w-90 py-2 px-2`}
                                onClick={() => this.moveOrderCustomer()}>
                                変更
                              </button>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                    {order.t_order_detail.map((item) =>
                      <div key={item.product_id} className="m-2 mb-1">
                        <div className="row mx-0 text-left">
                          <div className="col px-0">{this.state.productMaster.find(p => p.product_id === item.product_id).product_name}</div>
                        </div>
                        <div className="row mx-0 text-right  align-self-center">
                          <div className="col-5 px-0 align-self-center">&yen;{Math.floor(item.price_before_tax).toLocaleString()}<br /><small>&yen;{item.price.toLocaleString()}(税込)</small></div>
                          <div className="col-7">
                            <div className="row">
                              <div className="col-auto text-center p-1 ml-1 align-self-center">
                                <button
                                  disabled={this.state.disabled || this.state.message_error[item.product_id]}
                                  className={`btn btn-active w-100 h-100 py-1 px-2`}
                                  onClick={() => { this.subOrder(order.order_id, item.product_id) }}>
                                  －
                                </button>
                              </div>
                              <div className="col text-center py-1 px-1 align-self-center border-textbox">
                                <div className="bg-white">{item.count}</div>
                              </div>
                              <div className="col-auto text-center p-1 mr-1 align-self-center">
                                <button
                                  disabled={this.state.disabled || this.state.message_error[item.product_id]}
                                  className={`btn btn-active w-100 h-100 py-1 px-2`}
                                  onClick={() => { this.addOrder(order.order_id, item.product_id) }}>
                                  ＋
                                </button>
                              </div>
                              <div className="col-auto align-self-center pl-1 pr-0">個</div>
                            </div>
                          </div>
                        </div>
                        <div className="row mx-0 text-right  align-self-center">
                          <div className="col px-0 text-danger">{this.state.message[item.product_id]}{this.state.message_error[item.product_id]}</div>
                          <div className="col-auto px-0">
                            <button
                              className={`btn btn-enable w-100 py-0 px-2`}
                              onClick={() => { this.deleteOrder(order.order_id, item.product_id) }}>
                              削除
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row mx-0 px-1-env pt-1 pb-2">
                  <div className="col">
                    <div className="row">
                      <div className="col-6 text-right">
                        小計
                      </div>
                      <div className="col-6 text-right">
                        &yen;{(this.getOrderTotal(order).subtotal).toLocaleString()}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 text-right">
                        税額
                      </div>
                      <div className="col-6 text-right">
                        &yen;{(this.getOrderTotal(order).tax).toLocaleString()}
                      </div>
                    </div>
                    {this.getOrderTotal(order).discount > 0 &&
                      <div className="row">
                        <div className="col-6 text-right">
                          税込金額
                        </div>
                        <div className="col-6 text-right">
                          &yen;{(this.getOrderTotal(order).subtotal + this.getOrderTotal(order).tax).toLocaleString()}
                        </div>
                      </div>
                    }
                    {this.getOrderTotal(order).discount > 0 &&
                      <div className="row">
                        <div className="col-6 text-right">
                          早期割引
                        </div>
                        <div className="col-6 text-right">
                          &yen;{(this.getOrderTotal(order).discount * -1).toLocaleString()}
                        </div>
                      </div>
                    }
                    <div className="row">
                      <div className="col-6 text-right">
                        合計
                      </div>
                      <div className="col-6 text-right">
                        &yen;{(this.getOrderTotal(order).total).toLocaleString()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="row mx-0">
              <div className="col py-1 px-5 align-self-center">
                <button
                  className={`btn btn-enable w-100 py-2 px-2`}
                  onClick={() => this.submitCancel()}>
                  この注文をキャンセルする
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderOrderSelect() {
    return (
      <div className="row mx-0">
        <div className="col px-0">
          <div className="row mx-0">
            <div className="col py-2 text-center">
              <span>指定されたお受け取り日時をお選びください</span>
            </div>
          </div>
          <div>
            {/* {this.state.my_order.filter(o => ([constClass.STATUS.UPAY].includes(o.status) && this.props.page === constClass.HISTORY)).length > 0 && // 履歴の場合に未決済があれば当セクションを表示
              <div className="row mx-0 px-3-env pt-2 pb-1 border-bottom">
                <div className="col py-1 px-1 align-self-center">
                  {this.state.my_order.filter(o => ([constClass.STATUS.UPAY].includes(o.status))) //未決済のみ
                    .sort((a, b) => (this.props.page === constClass.RECEIVESELECT ? Date.parse(a.receive_date) - Date.parse(b.receive_date) : (a.plan_id === b.plan_id) ? b.order_id - a.order_id : b.plan_id - a.plan_id)).map((order) =>
                      this.renderOrderSelectdetail(order)
                    )}
                </div>
              </div>
            } */}
            <div className="row mx-0 px-3-env pt-2 pb-1">
              <div className="col py-1 px-1 align-self-center">{/* 企画ID降順・注文番号降順 */}
                {this.state.my_order.filter(o => ([constClass.STATUS.REG, constClass.STATUS.PRE].includes(o.status) && (this.props.page === constClass.HISTORY || o.delivery_flag !== constClass.FLAG.ON))) //予約済み・事前通知済みのみ、履歴ではない（受取選択）場合は配送ではないデータのみ
                  .sort((a, b) => (this.props.page === constClass.RECEIVESELECT ? Date.parse(a.receive_date) - Date.parse(b.receive_date) : (a.plan_id === b.plan_id) ? b.order_id - a.order_id : b.plan_id - a.plan_id)).map((order) =>
                    this.renderOrderSelectdetail(order)
                  )}
              </div>
            </div>
            <div className="row mx-0 px-3-env pt-2 pb-1 border-top">
              <div className="col py-1 px-1 align-self-center">
                {this.state.my_order.filter(o => (![constClass.STATUS.REG, constClass.STATUS.PRE, constClass.STATUS.UPAY, constClass.STATUS.CUPAY].includes(o.status) && this.props.page === constClass.HISTORY)) //履歴の場合の予約済み・事前通知済み以外
                  .sort((a, b) => (this.props.page === constClass.HISTORY ? a.order_id - b.order_id : Date.parse(a.receive_date) - Date.parse(b.receive_date))).map((order) =>
                    this.renderOrderSelectdetail(order)
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderOrderSelectdetail(order) {
    return <div key={order.order_id} className="row mx-0 my-3 text-center">
      <div className="col px-0 bg-white">
        <button
          className={`btn ${order.status === constClass.STATUS.UPAY ? `btn-submit-alert` : `btn-enable`} w-100 h-100 py-2 px-2`}
          onClick={() => this.submitCurrentOrder(order.order_id, ((this.props.page === constClass.HISTORY && this.checkOrderChangeLimit(order.order_id)) || order.status === constClass.STATUS.CCL) ?
            constClass.CHANGE : constClass.RECEIVE)}>
          <h4 className="mb-0 font-weight-bold">{this.getShortDateString(order.receive_date)} お受け取り分</h4>
          <span>{order.plan_name} (No.{order.receipt_num}){this.props.page === constClass.HISTORY &&
            <span><br />{constClass.STATUS_NAME_USER[order.status]}&nbsp;
              {this.checkOrderChangeLimit(order.order_id) && <span>{this.getShortDateString(this.getChangeLimitFromOrder(order))}まで{order.status === constClass.STATUS.UPAY ? 'にお支払いください' : '変更可能'}</span>}
            </span>
          }</span>
        </button>
      </div>
    </div>
  }


  renderReceive() {
    return (
      <div className="row mx-0">
        <div className="col px-0">
          {!(this.state.my_order.find(o => o.order_id === this.props.current_order)) &&
            <div className="row mx-0 px-0-env pt-1 pb-2">
              <div className="col text-center">情報が取得できませんでした。</div>
            </div>
          }
          {this.state.my_order.find(o => o.order_id === this.props.current_order) &&
            <div>
              {this.props.page === constClass.RECEIVE &&
                <div>
                  {this.state.my_order.find(o => o.order_id === this.props.current_order).status === constClass.STATUS.FIN &&
                    <div className="row mx-0 px-0-env pt-1 pb-2">
                      <div className="col text-center">お受け取りが完了しています。</div>
                    </div>
                  }
                  {this.state.my_order.find(o => o.order_id === this.props.current_order).status === constClass.STATUS.DFIN &&
                    <div className="row mx-0 px-0-env pt-1 pb-2">
                      <div className="col text-center">発送が完了しています。</div>
                    </div>
                  }
                  {this.state.my_order.find(o => o.order_id === this.props.current_order).status === constClass.STATUS.CCL &&
                    <div className="row mx-0 px-0-env pt-1 pb-2">
                      <div className="col text-center">この注文はキャンセルされました。</div>
                    </div>
                  }
                  {this.state.my_order.find(o => o.order_id === this.props.current_order).status === constClass.STATUS.CUPAY &&
                    <div className="row mx-0 px-0-env pt-1 pb-2">
                      <div className="col text-center">決済期限が切れています。お手数ですが、再度ご注文ください。</div>
                    </div>
                  }
                  {this.state.my_order.find(o => o.order_id === this.props.current_order).status === constClass.STATUS.UPAY &&
                    <div>
                      <div className="row mx-0 px-0-env pt-1">
                        <div className="col text-center">
                          <p className="mb-0 text-danger">お支払いが完了していません</p>
                        </div>
                      </div>
                      <div className="row mx-0 pb-2">
                        <div className="col py-0 px-5 align-self-center">
                          <button
                            className={`btn btn-submit-alert w-100 py-2 px-2`}
                            onClick={() => this.submitPayment(this.props.current_order)}>
                            決済画面へ
                          </button>
                        </div>
                      </div>
                    </div>
                  }
                  {([constClass.STATUS.REG, constClass.STATUS.PRE].includes(this.state.my_order.find(o => o.order_id === this.props.current_order).status) &&
                    this.state.my_order.find(o => o.order_id === this.props.current_order).delivery_flag !== constClass.FLAG.ON) &&
                    <div>
                      <div className="row mx-0 px-0-env pt-1 pb-2">
                        <div className="col text-center">
                          画面をスタッフにお見せください。<br />
                          「受け取る」ボタンはスタッフの確認後押してください。
                        </div>
                      </div>
                      <div className="row mx-0 px-0-env pt-1 pb-2">
                        <div className="col text-center">
                          <button
                            disabled={this.state.disabled}
                            className={`btn-lg btn-submit-alert w-75 py-3 p-env-bottom`}
                            onClick={() => { this.submitReceive() }}>
                            受け取る
                          </button>
                        </div>
                      </div>
                    </div>
                  }
                  {([constClass.STATUS.REG, constClass.STATUS.PRE].includes(this.state.my_order.find(o => o.order_id === this.props.current_order).status) &&
                    this.state.my_order.find(o => o.order_id === this.props.current_order).delivery_flag === constClass.FLAG.ON) &&
                    <div className="row mx-0 px-0-env pt-1 pb-2">
                      <div className="col text-center">受取日に商品をお届けします。</div>
                    </div>
                  }
                </div>
              }
              {this.props.page === constClass.CHANGE &&
                <div>
                  {this.state.my_order.find(o => o.order_id === this.props.current_order).status === constClass.STATUS.CCL &&
                    <div className="row mx-0 px-0-env pt-1 pb-2">
                      <div className="col text-center">
                        この注文はキャンセルされました。<br />
                      </div>
                    </div>
                  }
                  {this.state.my_order.find(o => o.order_id === this.props.current_order).status === constClass.STATUS.FIN &&
                    <div className="row mx-0 px-0-env pt-1 pb-2">
                      <div className="col text-center">
                        お受け取りが完了しました。<br />
                        ご利用ありがとうございました。<br />
                      </div>
                    </div>
                  }
                  {this.state.my_order.find(o => o.order_id === this.props.current_order).status === constClass.STATUS.CUPAY &&
                    <div className="row mx-0 px-0-env pt-1 pb-2">
                      <div className="col text-center">
                        決済期限が切れています。<br />
                        お手数ですが、再度ご注文ください。
                      </div>
                    </div>
                  }
                  {this.state.my_order.find(o => o.order_id === this.props.current_order).status === constClass.STATUS.UPAY &&
                    <div>
                      <div className="row mx-0 px-0-env pt-1">
                        <div className="col text-center">
                          <p className="mb-0 text-danger">お支払いが完了していません</p>
                        </div>
                      </div>
                      <div className="row mx-0 pb-2">
                        <div className="col py-0 px-5 align-self-center">
                          <button
                            className={`btn btn-submit-alert w-100 py-2 px-2`}
                            onClick={() => this.submitPayment(this.props.current_order)}>
                            決済画面へ
                          </button>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              }
              <div className="row mx-0 px-3-env pt-2 pb-2">
                {this.state.my_order.filter(o => o.order_id === this.props.current_order).map(order =>
                  <div key={order.order_id} className="col-12 bg-white py-1 px-1 align-self-center border-textbox rounded">
                    <div className="row mx-0 text-center">
                      <div className="col px-0 bg-white"><h4 className="mb-0 text-danger">注文番号　No. {order.receipt_num}</h4></div>
                    </div>
                    <div className="row mx-0 mb-2 text-center">
                      <div className="col px-0 bg-white">{order.customer_name} 様</div>
                    </div>
                    <div className="col py-2">
                      <div className="row">
                        <div className="col-6 text-right">
                          点数
                        </div>
                        <div className="col-6 text-right">
                          {order.t_order_detail.reduce((sumcount, detail) => sumcount + detail.count, 0)}個
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 text-right">
                          小計
                        </div>
                        <div className="col-6 text-right">
                          &yen;{Math.floor(order.subtotal).toLocaleString()}
                        </div>
                      </div>
                      <div className="row border-bottom mb-1">
                        <div className="col-6 text-right">
                          税額
                        </div>
                        <div className="col-6 text-right">
                          &yen;{Math.floor(order.tax).toLocaleString()}
                        </div>
                      </div>
                      {order.discount > 0 &&
                        <div className="row border-bottom mb-1">
                          <div className="col-6 text-right">
                            税込金額
                          </div>
                          <div className="col-6 text-right">
                            &yen;{(Math.floor(order.total) + Math.floor(order.discount)).toLocaleString()}
                          </div>
                        </div>
                      }
                      {order.discount > 0 &&
                        <div className="row mb-1">
                          <div className="col-6 text-right">
                            早期割引
                          </div>
                          <div className="col-6 text-right">
                            &yen;{Math.floor(order.discount * -1).toLocaleString()}
                          </div>
                        </div>
                      }
                      <div className="row text-danger">
                        <div className="col-6 text-right">
                          <h4 className="mb-0">合計</h4>
                        </div>
                        <div className="col-6 text-right">
                          <h4 className="mb-0">&yen;{Math.floor(order.total).toLocaleString()}</h4>
                        </div>
                      </div>
                      {order.payment_fin_flag === constClass.FLAG.ON &&
                        <div className="row justify-content-center mt-1">
                          <div className="col-auto bg-danger text-white py-1">
                            <h4 className="mb-0">※お支払い済み</h4>
                          </div>
                        </div>
                      }
                      <div className="row mx-0 text-left mt-4">
                        <div className="col px-0 bg-white">{order.plan_name}</div>
                      </div>
                      {order.t_order_detail.map(detail =>
                        <div key={detail.product_id}>
                          <div className="row mx-1 text-left">
                            <div className="col-1 px-0 bg-white">{detail.product_code}</div>
                            <div className="col-11 px-0 bg-white">{detail.product_name}</div>
                          </div>
                          <div className="row mx-2 text-right">
                            <div className="col-6 px-0 bg-white">{detail.count}個</div>
                            <div className="col-6 px-0 bg-white">&yen;{Math.floor(detail.price_before_tax * detail.count).toLocaleString()}<br /><small>&yen;{(detail.price * detail.count).toFixed(2).toLocaleString()}(税込)</small></div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {([constClass.STATUS.REG, constClass.STATUS.PRE].includes(this.state.my_order.find(o => o.order_id === this.props.current_order).status) &&
                !this.checkOrderChangeLimit()) &&
                <div>
                  <div className="row mx-0 px-0-env pt-1 pb-2">
                    <div className="col text-center">
                      変更期日を過ぎているため、<br />
                      注文内容の変更・キャンセルはできません。<br />
                    </div>
                  </div>
                  <div className="row mx-0 px-0-env pt-1 pb-2">
                    <div className="col text-center">
                      変更が必要な場合は、<br />
                      受取店舗へご連絡をお願いします。<br />
                      {this.state.my_order.filter(o => o.order_id === this.props.current_order).map((order) =>
                        this.state.shopMaster.find(s => s.shop_id === order.receive_shop) ? this.state.shopMaster.find(s => s.shop_id === order.receive_shop).shop_name + ' ' + this.state.shopMaster.find(s => s.shop_id === order.receive_shop).shop_tel : null
                      )}
                    </div>
                  </div>
                </div>
              }
            </div>
          }
        </div>
      </div>
    );
  }

  renderEmployee() {
    return (
      <div className="row mx-0">
        <div className="col px-0">
          <div className="row mx-0 my-3 text-center">
            <div className="col px-0">
              西鉄グループ従業員の方は、<br />
              以下のボタンで従業員登録をお願いします。
            </div>
          </div>
          {(this.state.my_customer && this.state.my_customer.employee_flag === '1') &&
            <div>
              <div className="row mx-0 px-0-env pt-1 pb-2">
                <div className="col text-center">
                  <button
                    disabled={true}
                    className={`btn-lg btn-submit-alert w-100 py-3 p-env-bottom`}
                    onClick={() => { this.submitEmployee() }}>
                    従業員登録済み
                  </button>
                </div>
              </div>
              <div className="row mx-0 px-0-env pt-1 pb-2">
                <div className="col text-center">
                  <span className="align-middle">従業員登録の解除は</span>
                  <button className="btn btn-link p-0 border-0" onClick={() => this.submitEmployeeCancel()}>こちら</button>
                </div>
              </div>
            </div>
          }
          {(!this.state.my_customer || this.state.my_customer.employee_flag !== '1') &&
            <div className="row mx-0 px-0-env pt-1 pb-2">
              <div className="col text-center">
                <button
                  disabled={this.state.disabled}
                  className={`btn-lg btn-submit-alert w-100 py-3 p-env-bottom`}
                  onClick={() => { this.submitEmployee() }}>
                  従業員登録
                </button>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }

  renderPayment() {
    return (
      <div className="row mx-0">
        <div className="col px-0">
          <Payment
            siteId={0}
            lineId={this.props.liff_access_token}
            id={this.state.payment_data.id}
            amount={this.state.payment_data.amount}
            fee={this.state.payment_data.fee}
            backUrl={this.state.payment_data.backUrl}
            returnUrl={this.state.payment_data.returnUrl}
            checkUrl={this.state.payment_data.checkUrl}
            abortUrl={`/`}
            backFunc={this.returnPayment}
            returnFunc={this.returnPayment} />
        </div>
      </div>
    );
  }

  renderBottom() {
    const Historybackbutton = (h) => {
      return (
        <div className="col-3 text-center p-0">
          <button
            className={`btn-lg btn-submit-enable w-100 py-3 p-env-bottom`}
            onClick={() => {
              if (this.props.history.length <= 1) {
                this.props.history.push('/');
              } else {
                this.props.history.goBack();
              }
              this.scrollPageTop();
            }}>
            戻る
          </button>
        </div>
      )
    }
    return (
      <footer className="footer text-center">
        <div className="container m-0 p-0 mw-100">
          {(this.props.page === constClass.REQUEST) &&
            <div className="row mx-0 bg-gray-alpha">
              <div className="col text-center p-0">
                <div className="row mx-0">
                  <div className="col-auto text-left py-1 px-2">
                    ●選択中商品　合計金額
                  </div>
                  <div className="col text-right py-1 px-2">
                    &yen;{(this.state.cart.reduce((sum, plan) => sum + this.getOrderTotal(plan).total, 0)).toLocaleString()}(税込)
                  </div>
                </div>
              </div>
            </div>
          }
          <div className="row mx-0">
            {(this.props.page === constClass.PLAN) && (
              <div className="col text-center p-0">
                <div className="row mx-0">
                  <Historybackbutton />
                  <div className="col-9 text-center p-0">
                    <button
                      disabled={!this.state.receive_shop || this.state.planMaster.length <= 0 || this.state.disabled}
                      className={`btn-lg btn-submit w-100 py-3 p-env-bottom`}
                      onClick={() => { this.submitReceiveShop() }}>
                      商品選択へ進む
                    </button>
                  </div>
                </div>
              </div>
            )}
            {(this.props.page === constClass.REQUEST) && (
              <div className="col text-center p-0">
                <div className="row mx-0">
                  <Historybackbutton />
                  <div className="col-9 text-center p-0">
                    <button
                      disabled={this.state.cart.length <= 0 || this.state.disabled}
                      className={`btn-lg btn-submit w-100 py-3 p-env-bottom`}
                      onClick={() => this.submitRequest()}>
                      お客様情報入力へ進む
                    </button>
                  </div>
                </div>
              </div>
            )}
            {(this.props.page === constClass.USER) && (
              <div className="col text-center p-0">
                <div className="row mx-0">
                  <Historybackbutton />
                  <div className="col-9 text-center p-0">
                    <button
                      // disabled={!this.state.name || !this.state.kana || !this.state.tel || this.state.disabled || !this.state.privacypolicy || this.state.message.tel
                      /*|| (this.state.planMaster.filter(p => this.state.cart.map(c => c.planId).includes(p.plan_id)).reduce((prev, p) => p.delivery_flag === constClass.FLAG.ON ? true : prev, false) && (!this.state.postal_code || !this.state.address))}*/
                      disabled={this.checkUserInvalid()}
                      className="btn-lg btn-submit w-100 py-3 p-env-bottom"
                      onClick={() => { this.submitCustomer(constClass.CONFIRM) }}>
                      ご注文確認画面へ進む
                    </button>
                  </div>
                </div>
              </div>
            )}
            {(this.props.page === constClass.CONFIRM) && (
              <div className="col text-center p-0">
                <div className="row mx-0">
                  <Historybackbutton />
                  <div className="col-9 text-center p-0">
                    <button
                      disabled={this.state.cart.length <= 0 ||
                        this.state.cart.reduce((flag, plan) => (flag || !plan.receiveDate ||
                          (plan.delivery_flag === constClass.FLAG.OFF && !plan.receiveTime) ||
                          (plan.delivery_flag === constClass.FLAG.ON && (!this.state.my_customer.customer_postal_code || !this.state.my_customer.customer_address || !plan.delivery_name || !plan.delivery_kana || !plan.delivery_tel || !plan.delivery_postal_code || !plan.delivery_address))
                        ), false) ||
                        this.state.disabled}
                      className="btn-lg btn-submit w-100 py-3 p-env-bottom"
                      onClick={() => { this.submit() }}>
                      {this.state.cart.filter(c => c.payment_flag === constClass.FLAG.ON).length > 0 ? "次へ" : "注文確定"}
                    </button>
                  </div>
                </div>
              </div>
            )}
            {(this.props.page === constClass.CHANGE) && (
              <div className="col text-center p-0">
                <div className="row mx-0">
                  <div className="col-3 text-center p-0">
                    <button
                      className={`btn-lg btn-submit-enable w-100 py-3 p-env-bottom`}
                      onClick={() => this.cancelChange()}>
                      戻る
                    </button>
                  </div>
                  {(this.checkOrderChangeLimit()) &&
                    <div className="col-9 text-center p-0">
                      <button
                        disabled={!this.state.my_order.reduce((flag, order) => (flag || order.changed), false)
                          || this.state.my_order.reduce((flag, order) => (flag || !order.receive_date || (order.delivery_flag !== constClass.FLAG.ON && !order.receive_time)), false)
                          || this.state.my_order.reduce((flag, order) => (flag || order.t_order_detail.length <= 0), false)
                          || Object.entries(this.state.message_error).reduce((flag, item) => flag || item[1], false)
                          || this.state.disabled}
                        className="btn-lg btn-submit w-100 py-3 p-env-bottom"
                        onClick={() => { this.submitChange() }}>
                        注文変更
                      </button>
                    </div>
                  }
                </div>
              </div>
            )}
            {(this.props.page === constClass.ORDERUSERCHANGE) && (
              <div className="col text-center p-0">
                <div className="row mx-0">
                  <div className="col-3 text-center p-0">
                    <button
                      className={`btn-lg btn-submit-enable w-100 py-3 p-env-bottom`}
                      onClick={() => this.cancelOrderCustomer()}>
                      戻る
                    </button>
                  </div>
                  <div className="col-9 text-center p-0">
                    <button
                      disabled={!this.state.name || !this.state.kana || !this.state.tel || this.state.disabled || !this.state.privacypolicy || this.state.message.tel
                        || (this.state.my_order.find(o => o.order_id === this.props.current_order).delivery_flag === constClass.FLAG.ON && (!this.state.postal_code || !this.state.address))}
                      className="btn-lg btn-submit w-100 py-3 p-env-bottom"
                      onClick={() => { this.submitOrderCustomer() }}>
                      保存
                    </button>
                  </div>
                </div>
              </div>
            )}
            {(this.props.page === constClass.ORDERSHOPCHANGE) && (
              <div className="col text-center p-0">
                <div className="row mx-0">
                  <div className="col-3 text-center p-0">
                    <button
                      className={`btn-lg btn-submit-enable w-100 py-3 p-env-bottom`}
                      onClick={() => this.cancelOrderShop()}>
                      戻る
                    </button>
                  </div>
                  <div className="col-9 text-center p-0">
                    <button
                      disabled={!this.state.receive_shop || this.state.disabled}
                      className="btn-lg btn-submit w-100 py-3 p-env-bottom"
                      onClick={() => { this.submitOrderShop() }}>
                      保存
                    </button>
                  </div>
                </div>
              </div>
            )}
            {(this.props.page === constClass.USERCHANGE) && (
              <div className="col text-center p-0">
                <div className="row mx-0">
                  <Historybackbutton />
                  <div className="col-9 text-center p-0">
                    <button
                      disabled={!this.state.name || !this.state.kana || !this.state.tel || this.state.disabled || !this.state.privacypolicy || this.state.message.tel}
                      className="btn-lg btn-submit w-100 py-3 p-env-bottom"
                      onClick={() => { this.submitCustomer('') }}>
                      保存
                    </button>
                  </div>
                </div>
              </div>
            )}
            {(this.props.page === constClass.RECEIVE
              || this.props.page === constClass.RECEIVESELECT
              || this.props.page === constClass.HISTORY) && (
                <div className="col text-center p-0">
                  <div className="row mx-0">
                    <Historybackbutton />
                  </div>
                </div>

              )}
            {(this.props.page === constClass.RECEIVEFINISH
              || this.props.page === constClass.ORDERFINISH
              || this.props.page === constClass.CHANGEFINISH
              || this.props.page === constClass.MEMBERFINISH
              || this.props.page === constClass.EMPLOYEE) && (
                <div className="col text-center p-0">
                  <div className="row mx-0">
                    <div className="col-3 text-center p-0">
                      <button
                        className={`btn-lg btn-submit-enable w-100 py-3 p-env-bottom`}
                        onClick={() => { this.props.history.push('/') }}>
                        戻る
                      </button>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </footer>
    );
  }

  render() {
    const pageClass = (page) => {
      switch (page) {
        case null:
        case constClass.PAYMENT:
          return 'page-full';
        case constClass.REQUEST:
          return 'page-btn-total';
        default:
          return 'page-btn';
      }
    }
    return (
      this.state.ready ? <div className="liff-top bg-lightwhite font-weight-bold">
        <ScrollToTop />
        {this.renderHeader()}
        <div className={pageClass(this.props.page)} ref={this.pageRef}>
          {(this.props.page !== constClass.PLAN) && this.renderPagetitle()}
          {(this.props.page === undefined || this.props.page === null || this.props.page === '') &&
            <div>
              {this.renderMenu()}
            </div>
          }
          {this.props.page === constClass.PLAN &&
            <div>
              {this.renderPlan()}
              {this.renderReceiveShop()}
            </div>
          }
          {this.props.page === constClass.REQUEST &&
            <div>
              {this.renderCondition()}
              {this.renderRequest()}
            </div>
          }
          {this.props.page === constClass.USER &&
            <div>
              {this.renderUser()}
            </div>
          }
          {this.props.page === constClass.CONFIRM &&
            <div>
              {this.renderConfirm()}
            </div>
          }
          {this.props.page === constClass.ORDERFINISH &&
            <div>
              {this.renderThanks()}
            </div>
          }
          {this.props.page === constClass.HISTORY &&
            <div>
              {this.renderOrderSelect()}
            </div>
          }
          {this.props.page === constClass.CHANGE &&
            <div>
              {this.checkOrderChangeLimit() && this.renderChange()}
              {!this.checkOrderChangeLimit() && this.renderReceive()}
            </div>
          }
          {this.props.page === constClass.ORDERUSERCHANGE &&
            <div>
              {this.renderUser()}
            </div>
          }
          {this.props.page === constClass.ORDERSHOPCHANGE &&
            <div>
              {this.renderReceiveShop()}
            </div>
          }
          {this.props.page === constClass.CHANGEFINISH &&
            <div>
              {this.renderThanks()}
            </div>
          }
          {this.props.page === constClass.MEMBERFINISH &&
            <div>
              {this.renderThanks()}
            </div>
          }
          {this.props.page === constClass.RECEIVESELECT &&
            <div>
              {this.renderOrderSelect()}
            </div>
          }
          {this.props.page === constClass.RECEIVE &&
            <div>
              {this.renderReceive()}
            </div>
          }
          {this.props.page === constClass.RECEIVEFINISH &&
            <div>
              {this.renderThanks()}
            </div>
          }
          {this.props.page === constClass.USERCHANGE &&
            <div>
              {this.renderUser()}
            </div>
          }
          {this.props.page === constClass.EMPLOYEE &&
            <div>
              {this.renderEmployee()}
            </div>
          }
          {this.props.page === constClass.PAYMENT &&
            <div>
              {this.renderPayment()}
            </div>
          }
        </div>
        {!(this.props.page === undefined || this.props.page === null || this.props.page === '') && this.renderBottom()}
        {this.state.disabled && <Loading />}
      </div>
        : this.state.not_liff_flag ? <div className="liff-top bg-lightwhite font-weight-bold">
          {this.renderHeader()}
          <div className="row mx-0">
            <div className="col px-0">
              <div className="row mx-0 my-3 text-center">
                <div className="col px-0">
                  LINEをご利用ください。
                </div>
              </div>
              <div className="row mx-0 px-0-env pt-1 pb-2">
                <div className="col text-center">
                  <button
                    disabled={this.state.disabled}
                    className={`btn-lg btn-enable w-100 py-3 p-env-bottom`}
                    onClick={() => { window.location.replace(process.env.REACT_APP_LIFF_URL); }}>
                    LINEで開く
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
          : <Loading />)
  }
}

export default withRouter(Order);